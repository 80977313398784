<div class="clearfix"></div>
<div class="top" style="background: url(assets/images/contact-banner.jpg);">
  <app-header ></app-header> 

  <div class="header2" >
    
    <div class="exam2">
      <h2 style="color: red; font-size: 24px;">Exam Hall / Face-2-Face & Online</h2>
    </div>

    <h1 class="heading">Year 4 Topic Tests 2025</h1>
    
    
    <h2 class="exam2">  CSSE | FSCE | GL | SET </h2>
  </div>
</div>

<div class="container">

  <div class="test-menu">

    <a class="btn-tab active" [routerLink]="['/topic-test']">Year 4 Topic Tests</a>
    <a class="btn-tab" [routerLink]="['/11Plus-mocktest']">Mock Test</a>

  </div>

  <div class="tabs">
    <div 
      *ngFor="let month of months"
      class="tab" 
      [ngClass]="{active: selectedMonth === month}"
      (click)="selectMonth(month)">
      {{ month }}
    </div>
  </div>

  <div class="timetable-wrapper">
    <div class="timetable">
      <div 
        *ngFor="let test of timetable[selectedMonth]"
        class="test-row">
        <div class="test-info enhanced">
          <div class="test-date">{{ test.date }}</div>
          <h3 class="testName">{{ test.name }}</h3>
          <p class="testTopic">{{ test.testtype }}</p>
          <p class="testTopic">{{ test.description }}</p>
        </div>
        <div class="test-centers">
          <div 
            *ngFor="let center of test.centers"
            class="center"
            [ngClass]="{'online': center.name === 'Online'}">
            <p>{{ center.name }}</p>
            <p>{{ center.time }}</p>
            <a class="book-btn" [href]="center.url" target="_blank">Book</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>