import { Component, OnInit } from '@angular/core';
import { TimetableService } from '../services/topictest.data.service';
import { MonthTimetable } from '../models/event.model';

@Component({
  selector: 'app-topic-test',
  templateUrl: './topic-test.component.html',
  styleUrls: ['./topic-test.component.css']
})
export class TopicTestComponent implements OnInit {
 months: string[] = [];
  selectedMonth: string = 'February';
  timetable: MonthTimetable = {};

  constructor(private timetableService: TimetableService) {}

  ngOnInit() {
    this.months = this.timetableService.getMonths();
    this.timetable = this.timetableService.getTimetable();
  }

  selectMonth(month: string) {
    this.selectedMonth = month;
  }

}
