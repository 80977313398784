<div class="tp-hd">

  <app-header ></app-header> 

  <div class="top" >
  

    <p> <strong>SEHYOG Academy</strong><br> Tutoring & Mock Exams | UK & UAE </p>
  
  
  <div class="trust-pilot">
  
    <img src="assets/images/trust-pilot.png" alt="ratings">
  
  </div>
    
  </div>

  <div class="nw-strp">

    <div class="nw-strp-lft">
      <h5>Sehyog Classes:</h5>
        <a class="bk-dm" href="https://www.sehyog.co.uk/freedemo">Book Free Demo</a>
    </div>

    <div class="nw-strp-rgt">
      <h5>Also Visit: </h5>
        <a class="to-nmt" [routerLink]="['/gsce-test']">GCSE Mock Test</a>
    </div>

  
  </div>

</div>
<!-- container starts -->

<div class="container-main">

  <div class="container">

    <!-- First Wave (Lower) -->
    <div class="wave-container wave1">
        <svg viewBox="0 0 1000 200" preserveAspectRatio="none">
            <defs>
                <linearGradient id="grad1" gradientUnits="userSpaceOnUse" x1="0" y1="0" x2="1000" y2="0">
                    <stop offset="0%" stop-color="#70e000"/>
                    <stop offset="20%" stop-color="#ffd500"/>
                    <stop offset="40%" stop-color="white"/>
                    <stop offset="60%" stop-color="cyan"/>
                    <stop offset="80%" stop-color="white"/>
                    <stop offset="100%" stop-color="#3a0ca3"/> <!-- Updated color -->
                </linearGradient>
            </defs>
            <path class="wave-path" d="M 0 100 Q 250 10, 500 100 T 1000 100" />
        </svg>
    </div>
  
    <!-- Second Wave (Upper) -->
    <div class="wave-container wave2">
        <svg viewBox="0 0 1000 200" preserveAspectRatio="none">
            <defs>
                <linearGradient id="grad2" gradientUnits="userSpaceOnUse" x1="0" y1="0" x2="1000" y2="0">
                    <stop offset="0%" stop-color="orange"/>
                    <stop offset="20%" stop-color="red"/>
                    <stop offset="40%" stop-color="white"/>
                    <stop offset="60%" stop-color="cyan"/>
                    <stop offset="80%" stop-color="white"/>
                    <stop offset="100%" stop-color="#00a6fb"/> <!-- Updated color -->
                </linearGradient>
            </defs>
            <path class="wave-path" d="M 0 100 Q 250 10, 500 100 T 1000 100" />
        </svg>
    </div>
  
    <!-- Main Image -->
    <img class="main-img" src="assets/images/webinar-img.png" alt="">
  
    
  </div>

</div>







<!-- <img class="cs-img" src="assets/images/students.png" alt=""> -->

  <!-- <div class="cs-sec1">
    <h2>FREE WEBINAR SERIES</h2>
    <h1>SEHYOG ACADEMY</h1>
    <h3>Tutoring & Mock Exams | UK & UAE </h3>
    <h4>Major Board Based Focus <br>
       <strong>AQA | OCR | EDEXCEL,  CSSE | FSCE | GL REDBRIDGE
        <br>GL KENT | GL BEXLEY | GL QE | GL HBS | BUCKS
       </strong> </h4>

    <a class="cta-1" (click)="openCalendar()">Register For Webinar</a>

  </div> -->

<!-- container ends here -->



<!-- sqare box starts here -->

<div class="ft-d">

  <div class="ft-d-crd">
    <img src="assets/images/calendar.png" alt="">

    <p>Every Weekend</p>
  </div>

  <div class="ft-d-crd">
    <img src="assets/images/time.png" alt="">

    <p>07:00 PM BST</p>
  </div>

  <div class="ft-d-crd">
    <img src="assets/images/reading.png" alt="">

    <p>Limited Seats</p>
  </div>

  <div class="ft-d-crd">
    <img src="assets/images/pound.png" alt="">

    <p>Free</p>
  </div>

</div>


<!-- square box ends here -->



<!-- strip1 starts here -->

<div class="strp-1">

  <div style="">
    <h2>LIMITED SEATES!</h2>
    <h3>Unlock your Educational Excellence</h3>
  </div>


  <a class="cta-2" (click)="openCalendar()">Book Your Seat Now</a>

</div>

<!-- strip 1 ends here -->

<!-- Calender Starts here -->

<div class="calendar">
  <h1>Webinar Calendar 2025</h1>
  <h2>SELECT THE DATE BELOW & CLICK BOOK BUTTON</h2>

  <div class="container2">
    <div class="toggle-buttons">
      <button [class.active]="activeTab === 'past'" (click)="setActiveTab('past')">Past</button>
      <button [class.active]="activeTab === 'upcoming'" (click)="setActiveTab('upcoming')">Upcoming</button>
    </div>
  
    <div class="cards">
      <div class="card-cal" *ngFor="let event of filteredEvents">
        <div class="card-header">{{ event.date }}</div>
        <div class="card-body">
          <h3>{{ event.title }}</h3>
          <p>Time: {{ event.time }}</p>
          <a class="book-btn" href={{event.link}}>Book Now</a>
        </div>
      </div>
    </div>
  </div>
  
</div>

<!-- calender ends here -->


<!-- Feature Section  -->

<div class="ft-wrsp">
  <h1>Why Attend this Workshop</h1>

  <div class="ft-wrsp-sc">

    <div class="ft-wrsp-sc-crd">
      <div class="cir">
        <h1>1</h1>
      </div>

      <h4 style="text-align: center;">AQA | OCR | EDEXCEL | CSSE | FSCE | GL </h4>
      <h5>Board base Focus</h5>

      <a class="cta-3" (click)="openCalendar()">Book Now</a>

    </div>

    <div class="ft-wrsp-sc-crd">
      <div class="cir">
        <h1>2</h1>
      </div>

      <h4>Valuable Tips</h4>
      <h5>Top Tips for GCSE & 11plus Success</h5>

      <a style="margin-top: 25px;" class="cta-3" (click)="openCalendar()">Book Now</a>

    </div>

    <div class="ft-wrsp-sc-crd">
      <div class="cir">
        <h1>3</h1>
      </div>

      <h4>Support & Guidance</h4>
      <h5>Mistakes to Avoid <br> Parent Support Guidance</h5>

      <a style="margin-top: 25px;" class="cta-3" (click)="openCalendar()">Book Now</a>

    </div>

  </div>


</div>

<!-- Feature Section Ends -->


<!-- Academy Deatils -->

<div class="sec-3">
  <div class="s3-left">
    <h1>SEHYOG ACADEMY</h1>
    <h3 style="font-weight: 400;">Providing comprehensive Support to <strong>11+, GCSE, AS/A</strong></h3>
    <h3 style="font-weight: 400;">Level Students since over a decade in <strong>United Kingdom.</strong></h3>
    <h3>Rated No. 1 in UK now also in UAE</h3>

    <a class="cta-6" (click)="openCalendar()">Book Your Seat Now</a>
  </div>

  <img src="assets/images/lg-cir.png" alt="">


</div>

<!-- Academy Details Ends -->


<!-- Testimonials  -->
<div class="sec-4">
  <h2>Join the community of more than</h2>
  <h1>10K Successful Students & Happy Parents!</h1>

  <div class="cs-sec">

    <div class="cs-crd">
      <div class="crd-cir">
        <h4 style="margin: 0px;">JS</h4>
      </div>
      <div class="info">
        <p style="font-weight: bold;">Jaya Rani</p>
        <p style="text-align: justify;">CMy son did Sehyog Mock Test, it’s very helpful to know which areas my son is
          lacking. Sehyog result analysis gives us understanding on which area the aspirant/parent needs to improve and
          concentrate on, which is very important in 11 plus journey, apart from that Sehyog also provides you guidance
          on how to submit CAF form with the school preferences and clarified most of our doubts. Thank you so much
          Sehyog team for your all your efforts.</p>

      </div>
    </div>

    <iframe  src="https://www.youtube.com/embed/3PjPMOdyV-A"
      title="Happy Parents Speak: Success Stories &amp; Testimonials 01" frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

  </div>

  <!-- Testimonial Ends -->

  <div class="cs-sec">


    <iframe src="https://www.youtube.com/embed/fVU0xDgiclA" title="Happy Parents Speak: Success Stories &amp; Testimonials 02" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

    <div class="cs-crd">
      <div class="crd-cir">
        <h4 style="margin: 0px;">MD</h4>
      </div>
      <div class="info">
        <p style="font-weight: bold;">Mini Dagar</p>
        <p style="text-align: justify;">My son attended the mock exams by 11plushelpclub and it helped us a lot. With
          the detailed results every week, we knew which area to work on. Topic wise tests were a great form of
          revision. All the help by whole team was fabulous which helped my son to secure a safe place at school of his
          choice. Big thanks to everyone out there for all the help and guidance - Mini Dagar</p>

      </div>
    </div>

  </div>
</div>

<!-- strip 2 starts here -->

<div class="strp-2">
  <h1>With our Proven Methodology and Years of Experience, <br>
    we help your child in their <strong>11+, GCSE, AS/A Level Success Journey</strong></h1>
  <a class="cta-4" (click)="openCalendar()">Book Your Seat Now</a>
</div>

<!-- strip 2 end here -->


<!-- complementry section starts here -->

<div class="card-main">

  <h1>COMPLEMENTARY WITH THIS WEBINAR!</h1>
  <div class="card-section">
    <div class="card"><img src="assets/images/4.jpg" alt="Practice Paper">
      <div class="card-content">
        <h3>PRACTICE PAPER</h3>
          <p>Real Exam Style Questions to test your Knowledge</p>
          <div class="underline">
          </div>
    </div>
  </div>
  <div class="card">
    <img src="assets/images/5.jpg" alt="Review and Analysis">
    <div class="card-content">
      <h3>REVIEW AND ANALYSIS</h3>
      <p>In Depth Professional Review &amp; Analysis</p>
      <div class="underline">

      </div>
    </div>
  </div>
  <div class="card">
    <img src="assets/images/6.jpg" alt="Marking and Feedback">
    <div class="card-content">
      <h3 >MARKING AND FEEDBACK</h3>
      <p >Highlight Strengths and Areas for Improvement</p>
      <div class="underline"></div>
    </div>
  </div>
</div>
</div>

<!-- complementry section end here -->

<!-- strip 3 -->

<div class="strp-3">
  <h1>UNLOCK all these Benefits and Kickstart your <br> ​Educational Excellence Journey</h1>
  <a class="cta-5" (click)="openCalendar()">Book Your Seat Now</a>
</div>

<!-- strip 3 ends here -->


<!-- FAQ Starts here -->

<div class="faq-sc">

  <h1>FAQs</h1>

  <div class="drop-d">

    <details>
      <summary>I have booked a SEHYOG test/session but did not receive the confirmation yet?</summary>
      <div>
          <p>Dear Parent, Confirmation email is automatically fired as soon as you book the test - please check your registered email inbox or junk folder. <br><p></p><br>
            Classroom Exams -<br>
            User could download the Hall Ticket online from SEHYOG account.<br> 1. Login to your SEHYOG account - SEHYOG.co.uk<br>2. Click on the Actions (little eye) button available far right on the confirmed booking row/line<br>3. Click 'Download' or 'Email' button
            <br><p></p><br>
            Online Exams - <br>
            1. Goto sehyog.co.uk<br>
            2. Goto ‘MY STUDENT’ listing <br>
            3. Click on ‘ONLINE EXAM’ under actions column<br>
            4. A new window will open - click TEST to do the test and click SCORE to review the score & test results.</p>
          
      </div>
  </details>
  
  <details>
    <summary>How to book a SEHYOG session?</summary>
    <div>
        <p>Its three step process - (1) goto sehyog.co.uk and sign up aka create an account (2) An email will be sent to verify the account, verify and login to your SEHYOG account (3) Add Student and goto session listing page to book a session - you can see listing on the landing page (or click on SEHYOG logo - top left), scroll down, click on the available session and follow instructions.</p>
    </div>
  </details>
  
  <details>
    <summary>How can I join Sehyog Telegram group chat for live support?</summary>
    <div>
        <p>Join Telegram group chat for live support using below link - <br>
          https://t.me/joinchat/SMnbMp4LZ9eEsm3h</p>
    </div>
  </details>

  </div>


  <h3 style="text-align: center; margin-top: 50px;">Still have questions? <br>
    Connect Directly on <strong style="font-weight: bold;">+44 7464772331</strong> & EMAIL <strong style="font-weight: bold;">academy@sehyog.co.uk</strong></h3 >


    <div class="so-sc">

      <img src="assets/images/linkedin.png" alt="linkedin">
      <img src="assets/images/facebook.png" alt="linkedin">
      <img src="assets/images/youtube.png" alt="linkedin">
      <img src="assets/images/twitter.png" alt="linkedin">
      <img src="assets/images/instagram.png" alt="linkedin">

    </div>


    <p style="width: 80%; margin-top: 100px; text-align: justify;">SEHYOG is an educational institute dedicated to providing comprehensive support to KS2, 11plus, KS3, 11plus & A-Level students to achieve excellence in education and personal development with honesty and transparency. The sessions, workshops/webinars conducted by SEHYOG do not guarantee 100% success in the real exams. No claim is made as to the accuracy or authenticity of the content of the sessions, workshops/webinars or the information shared via email, website, social media etc. SEHYOG or its volunteers/staff do not accept any liability to any person or firm for the information or advice (or the use of such information or advice) which is provided in the sessions/workshops, email or on social media. The information on the website, phone, emails, social media and sessions/workshops is provided on the basis that all persons accessing the information undertake responsibility for assessing the relevance and accuracy of its content.</p>

 

</div>

<!-- FAQ Ends here -->


<!-- POP UP OPEN HERE  -->

<div class="modal" *ngIf="showCalendar" [@fadeInOut]="modalState">
  <div class="modal-content">
    <span class="close-btn" (click)="closeCalendar()">&times;</span>
    <h1>Webinar Calendar 2025</h1>
    <h2>SELECT THE DATE BELOW & CLICK BOOK BUTTON</h2>

    <div class="container2">
      <div class="toggle-buttons">
        <button [class.active]="activeTab === 'past'" (click)="setActiveTab('past')">Past</button>
        <button [class.active]="activeTab === 'upcoming'" (click)="setActiveTab('upcoming')">Upcoming</button>
      </div>
    
      <div class="cards">
        <div class="card-cal" *ngFor="let event of filteredEvents">
          <div class="card-header">{{ event.date }}</div>
          <div class="card-body">
            <h3>{{ event.title }}</h3>
            <p>Time: {{ event.time }}</p>
            <a class="book-btn" href="{{ event.link }}" target="_blank">Book Now</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- POP UP CLOSED HERE -->


