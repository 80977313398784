import { MonthTimetable } from '../models/event.model';
import * as i0 from "@angular/core";
var TimetableService = /** @class */ (function () {
    function TimetableService() {
        this.timetableData = {
            'February': [
                {
                    date: 'Sat 08',
                    name: 'Mock Test',
                    description: 'Full Mock | Maths | Paper-2 (Calc)',
                    testtype: "",
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 3:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/a0ad593c877d920bc925d9934a66f15d' },
                    ]
                },
                {
                    date: 'Sun 09',
                    name: 'Mock Test',
                    description: 'Full Mock | Maths | Paper-2 (Calc)',
                    testtype: "",
                    centers: [
                        { name: 'Ilford', time: '05:30 PM - 07:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/d17bc1dd1262bb6bb19f36431e81da2c' },
                        { name: 'Basildon', time: '4:00 PM - 06:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/9b2f63849a594f6a5a8f97cf9dc015aa' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/5a53e9120e0faced511df34a9cafa392' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/48410d81de98bfb6dede76521b2c1c9a' }
                    ]
                },
                {
                    date: 'Sun 15',
                    name: 'Mock Test',
                    description: 'Full Mock | Maths | Paper-3 (Calc)',
                    testtype: "",
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 3:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/6a8e26e10d536252c6654593fe5bb77c' },
                    ]
                },
                {
                    date: 'Sun 16',
                    name: 'Mock Test',
                    description: 'Full Mock | Maths | Paper-3 (Calc)',
                    testtype: "",
                    centers: [
                        { name: 'Ilford', time: '05:30 PM - 07:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/d41e8db3ff598b9e43d3c6a5783c42a8' },
                        { name: 'Basildon', time: '4:00 PM - 06:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/5d9895611517786fb024558792c6b3fe' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/d6bf663f59c56921f8c8f7f2a73e07be' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/dbbb73a062dfd0f20da83d2aba8f6bbc' }
                    ]
                },
                {
                    date: 'Sun 22',
                    name: 'Mock Test',
                    testtype: "",
                    description: 'Full Mock | Physics | Paper-1',
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 3:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/6c21c613d2ccac80af03f1361e9bd43a' },
                    ]
                },
                {
                    date: 'Sun 23',
                    name: 'Mock Test',
                    testtype: "",
                    description: 'Full Mock | Physics | Paper-1',
                    centers: [
                        { name: 'Ilford', time: '05:30 PM - 07:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/15c770f5caaf5932b1ec82b7b2ebcc28' },
                        { name: 'Basildon', time: '4:00 PM - 06:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/d714bb6729e4ed30775c1aba95ec3c79' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/b3dd386e791d30fc6b3f6d9f11ff320d' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/7ffd97141cbcae7c8d4bd10c46190fef' }
                    ]
                },
            ],
            'March': [
                {
                    date: 'Sat 01',
                    name: 'Mock Test',
                    description: 'Full Mock | Physics| Paper-2',
                    testtype: "",
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 03:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/0b5bfa1a9439ea58158cbc1d19d27dd6' },
                    ]
                },
                {
                    date: 'Sun 02',
                    name: 'Mock Test',
                    description: 'Full Mock | Physics| Paper-2',
                    testtype: "",
                    centers: [
                        { name: 'Ilford', time: '05:30 PM - 07:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/8ee55312f865590a905bbe7d3e9e14b7' },
                        { name: 'Basildon', time: '04:00 PM - 06:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/2ad14c345a933925b98100a1e7ab350c' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/b58942f119cab7cd1a8b8242c592821b' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/d3b604df835dcb2b1fc5161679cd2242' }
                    ]
                },
                {
                    date: 'Sat 08',
                    name: 'Mock Test',
                    description: 'Full Mock | Chemistry | Paper-1',
                    testtype: "",
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 03:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/d631d3873edf45d5ad6390657804345b' },
                    ]
                },
                {
                    date: 'Sun 09',
                    name: 'Mock Test',
                    description: 'Full Mock | Chemistry | Paper-1',
                    testtype: "",
                    centers: [
                        { name: 'Ilford', time: '05:30 PM - 07:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/1e2dcf8758a78c7e9afd124001425741' },
                        { name: 'Basildon', time: '4:00 PM - 06:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/1a6dcf360bc490f3a3c7fb5a0ca30bb9' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/20badf2b2474e15c191924422ac75c5a' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/0418f461b20a3529f3bb8e4e7f366d45' }
                    ]
                },
                {
                    date: 'Sat 15',
                    name: 'Mock Test',
                    description: 'Full Mock | Chemistry | Paper-2',
                    testtype: "",
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 03:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/1571187e828b7c1a1813a423a68f9364' },
                    ]
                },
                {
                    date: 'Sun 16',
                    name: 'Mock Test',
                    description: 'Full Mock | Chemistry | Paper-2',
                    testtype: "",
                    centers: [
                        { name: 'Ilford', time: '05:30 PM - 07:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/643093c95a45a257356d4b70c33e82fa' },
                        { name: 'Basildon', time: '4:00 PM - 06:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/cd6e61cd26a2697326ae7af95d9ce66c' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/12fd59bcd57bbe883dbd86f792830bc2' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/dad53e19738e70ea31bcf2623ced05dc' }
                    ]
                },
                {
                    date: 'Sat 29',
                    name: 'Mock Test',
                    description: 'Full Mock | Biology | Paper-1',
                    testtype: "",
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 03:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/f982bbb78037c15949b91b8b5b9217a1' },
                    ]
                },
                {
                    date: 'Sun 30',
                    name: 'Mock Test',
                    description: 'Full Mock | Biology | Paper-1',
                    testtype: "",
                    centers: [
                        { name: 'Ilford', time: '05:30 PM - 07:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/35f60ed4a122c61897dd6368e46cdb54' },
                        { name: 'Basildon', time: '04:00 PM - 06:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/07bb205b3d6ac096ebbc7607ad67a5cb' },
                        { name: 'Amersham', time: '03:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/42f5460e010bdca671b69dc68f3301e5' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/e74b5cf76e0f6f045e0cafc6ab214939' }
                    ]
                }
            ],
            'April': [
                {
                    date: 'Sat 05',
                    name: 'Mock Test',
                    description: 'Full Mock | Biology| Paper-2',
                    testtype: "",
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 3:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/b80e74b6a086abc47c87cd756d1377a7' },
                    ]
                },
                {
                    date: 'Sun 06',
                    name: 'Mock Test',
                    description: 'Full Mock | Biology| Paper-2',
                    testtype: "",
                    centers: [
                        { name: 'Ilford', time: '05:30 PM - 07:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/6ea782c513b1bafb2d50b393fbef5427' },
                        { name: 'Basildon', time: '04:00 PM - 06:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/7fec2568f694ce46254216a936e64c60' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/8653352e9898d4a176896725264369fb' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/62d731d81d339641025b967ebc796745' }
                    ]
                },
                {
                    date: 'Sat 12',
                    name: 'Mock Test',
                    description: 'Full Mock | English| Paper-1 & Paper-3',
                    testtype: "",
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 03:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/1199b61fa16038d4ca73daf3200550d2' },
                    ]
                },
                {
                    date: 'Sun 13',
                    name: 'Mock Test',
                    description: 'Full Mock | English| Paper-1 & Paper-3',
                    testtype: "",
                    centers: [
                        { name: 'Ilford', time: '5:30 PM - 07:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/31a16135e5ed0fb676de7081be1cb3d6' },
                        { name: 'Basildon', time: '4:00 PM - 06:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/e94ef9cae554ced507e2c050ac99d83f' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/4006d6a23c7787ab5132f114f512f06a' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/8777ad2374fb69ce840b5ac455a0160d' }
                    ]
                },
                {
                    date: 'Sat 19',
                    name: 'Mock Test',
                    description: 'Full Mock | English| Paper-2 & Paper-4',
                    testtype: "",
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 03:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/8b06efc21bf0c534c27606de737c61e7' },
                    ]
                },
                {
                    date: 'Sun 20',
                    name: 'Mock Test',
                    description: 'Full Mock | English| Paper-2 & Paper-4',
                    testtype: "",
                    centers: [
                        { name: 'Ilford', time: '05:30 PM - 07:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/70c597bca7a178404579271c7af72cc0' },
                        { name: 'Basildon', time: '4:00 PM - 06:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/a9288dcf8e709dedf419ef33a2322f9e' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/a450e858dc1a6cd2564fe3ce27fd30e5' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/12280bbdf78e78b8e1332329c71d9e99' }
                    ]
                },
                {
                    date: 'Sun 26',
                    name: 'Mock Test',
                    description: 'Full Mock | Computer | Paper-1 & Paper-2',
                    testtype: "",
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 3:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/b9c66a6c5e46e5b8896f0aac7a693230' },
                    ]
                },
                {
                    date: 'Sun 27',
                    name: 'Mock Test',
                    description: 'Full Mock | Computer | Paper-1 & Paper-2',
                    testtype: "",
                    centers: [
                        { name: 'Ilford', time: '05:30 PM - 07:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/cfbaea1ceba9e909d7529006c80eb472' },
                        { name: 'Basildon', time: '4:00 PM - 06:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/975be5defa34afbe79d9edad144b375f' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/0c8dd6de7778ed6f5ec8ac5ed97645b4' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test/e1559df798bd2774af3ab62c0cc57c24' }
                    ]
                },
            ],
        };
    }
    TimetableService.prototype.getMonths = function () {
        return ['February', 'March', 'April',];
    };
    TimetableService.prototype.getTimetable = function () {
        return this.timetableData;
    };
    TimetableService.ngInjectableDef = i0.defineInjectable({ factory: function TimetableService_Factory() { return new TimetableService(); }, token: TimetableService, providedIn: "root" });
    return TimetableService;
}());
export { TimetableService };
