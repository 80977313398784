import { OnInit, OnDestroy } from '@angular/core';
import { CmsserviceService } from '../../common-cms-service/cmsservice.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
var HomeComponent = /** @class */ (function () {
    function HomeComponent(cmsService, router, titleService, meta) {
        this.cmsService = cmsService;
        this.router = router;
        this.titleService = titleService;
        this.meta = meta;
        this.ngUnsubscribe = new Subject();
        this.title = 'Sehyog';
        this.slides = [
            { image: 'assets/images/sl-1.png', title: 'Online Tutoring', description: 'Click for more', route: '/11Plus-mocktest' },
            { image: 'assets/images/sl-2.png', title: 'Mock Assesment', description: 'Click for more', route: '11Plus-classes' },
            { image: 'assets/images/sl-3.png', title: 'Crash Courses', description: 'Click for more', route: 'https://example.com/3' },
        ];
        this.items = [
            { image: 'assets/images/event1.png', title: 'CSSE04 - 4th SET of CSSE Style 11+', location: 'Southend-on-Sea + 2 more', date: '11/05/2025' },
            { image: 'assets/images/event1.png', title: 'CSSE02 - 2nd SET of CSSE Style 11+', location: 'Southend-on-Sea + 2 more', date: '06/04/2025' },
            { image: 'assets/images/event1.png', title: 'CSSE03 - 3rd SET of CSSE Style 11+', location: 'Southend-on-Sea + 2 more', date: '27/04/2025' },
            { image: 'assets/images/event1.png', title: 'CSSE03 - 3rd SET of CSSE Style 11+', location: 'Southend-on-Sea + 2 more', date: '27/04/2025' },
        ];
        this.currentSlide = 0;
        //Gallery Section 
        this.gal_images = [
            { src: 'assets/images/ps1.jpg', title: 'Online Class Session' },
            { src: 'assets/images/gal_img1.jpg', title: 'Award Ceremony' },
            { src: 'assets/images/gal_img2.jpg', title: 'Exam Preparation' },
            { src: 'assets/images/gal_img3.jpg', title: 'Cultural Event' },
        ];
        this.currentIndex = 0;
        this.isSliding = false;
        this.meta.addTags([
            { name: 'description', content: 'This is 11plus Examination portal' },
            { name: 'keywords', content: 'gcse revision, gcse maths, gcse past papers, gcse science, gcse maths past papers, gcse revision websites, science gcse online, english gcse revision, mathematics gcse, science and maths tutor, online maths test, english language gcse revision, edexcel gcse maths, 11 plus exam papers, 11 plus tuition, 11 plus tutor, 11 plus tutors near me, 11 plus mock exams, 11 plus mock test, 11 plus papers, 11 plus test papers, eleven plus tuition, eleven plus mock exams, eleven plus papers, eleven plus tutors, 11 plus exam tuition, eleven plus exam papers, 11 plus exam tutors, 11 plus exam mock test, eleven plus test papers, eleven plus mock test, eleven plus verbal reasoning' }
        ]);
    }
    HomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.startAutoSlide();
        this.cmsService.commonSiteSettingSingleton().subscribe(function (res) {
            _this.siteSetting = res;
        });
        $('.loader-bg').fadeIn();
        this.titleService.setTitle(this.title);
        this.upcomingTest();
    };
    /** comment
     * upcoming Session listing home page
     */
    HomeComponent.prototype.upcomingTest = function () {
        var _this = this;
        this.cmsService.upcomingEventsRecord()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (res) {
            _this.images = res.data;
        });
    };
    HomeComponent.prototype.startAutoSlide = function () {
        var _this = this;
        this.slideInterval = setInterval(function () {
            _this.currentSlide = (_this.currentSlide + 1) % _this.slides.length;
        }, 5000); // Change slide every 3 seconds
    };
    HomeComponent.prototype.onSlideClick = function (index) {
        this.router.navigate([this.slides[index].route]); // Navigate to route instead of opening external link
    };
    Object.defineProperty(HomeComponent.prototype, "currentImage", {
        get: function () {
            return this.gal_images[this.currentIndex].src;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HomeComponent.prototype, "currentTitle", {
        get: function () {
            return this.gal_images[this.currentIndex].title;
        },
        enumerable: true,
        configurable: true
    });
    HomeComponent.prototype.changeImage = function (index) {
        var _this = this;
        if (index !== this.currentIndex) {
            this.isSliding = true; // Start animation
            setTimeout(function () {
                _this.currentIndex = index;
                _this.isSliding = false; // Reset animation
            }, 300);
        }
    };
    HomeComponent.prototype.ngOnDestroy = function () {
        if (this.slideInterval) {
            clearInterval(this.slideInterval);
        }
    };
    HomeComponent.prototype.openWhatsApp = function () {
        window.open('https://wa.me/447464772331', '_blank');
    };
    return HomeComponent;
}());
export { HomeComponent };
