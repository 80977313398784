import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Router} from '@angular/router';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CmsserviceService {
  serverUrl = environment.baseURL;
  url: string;
  public AdminCommonData = new ReplaySubject<any>(1);
  constructor(
    private http: HttpClient,
    public router: Router,
  ) { }

/** comment
 * to cms page get the data
 */
getCmsData(pageslug: any): any {
    return this.http.get<any>(`${this.serverUrl}api/f/get_cms_page_data/` + pageslug).pipe(map(data => {
        return data;
        })
    );
  }

/** comment
 * get faq category
 */
  getFaqCategoryList(): any {
    return this.http.get<any>(`${this.serverUrl}api/f/get_faq_categories`).pipe(map(data => {
        return data;
        })
    );
  }

/** comment
 * get faq category records
 */
getFaqCategoryData(slugFaq): any {
  return this.http.get<any>(`${this.serverUrl}api/f/get_faq/` + slugFaq).pipe(map(data => {
      return data;
      })
  );
}

/** comment
 * submit contact us form, get in touch form, support form
 */
submitFormsService(formData: any): any {
  return this.http.post<any>(`${this.serverUrl}api/f/form_submit`, formData).pipe(map(res => {
      return res;
      })
  );
}

/** comment
 * get test categories
 */
getTestCategories(): any {
  return this.http.get<any>(`${this.serverUrl}api/f/test_categories`).pipe(map(res => {
      return res;
      })
  );
}

/** comment
 * get previous test categories
 */
getPreviousTestCategories(): any {
  return this.http.get<any>(`${this.serverUrl}api/f/previous_test_categories`).pipe(map(res => {
      return res;
      })
  );
}

/** comment
 * get test by category
 */
getAllTestByCategory(slug: any, paging: number): any {
  return this.http.post<any>(`${this.serverUrl}api/f/category_test_listing/` + slug, paging).pipe(map(res => {
      return res;
      })
  );
}

/** comment
 * get previous test by category
 */
getAllPreviousTestByCategory(slug: any, paging: number): any {
  return this.http.post<any>(`${this.serverUrl}api/f/category_previous_test_listing/` + slug, paging).pipe(map(res => {
      return res;
      })
  );
}

/** comment
 * get test
 */
getTest(parent: any, test: any): any {
  const user = +localStorage.getItem('userid');
  const user_id = ((user)?user:0);
  return this.http.post<any>(`${this.serverUrl}api/f/test_detail/` + parent + '/' + test, {user_id: user_id}).pipe(map(res => {
      return res;
      })
  );
}

/** comment
 * get previous test
 */
getPreviousTest(parent: any, test: any): any {
  const user = +localStorage.getItem('userid');
  const user_id = ((user)?user:0);
  return this.http.post<any>(`${this.serverUrl}api/f/previous_test_detail/` + parent + '/' + test, {user_id: user_id}).pipe(map(res => {
      return res;
      })
  );
}

/** comment
 * photo gallary
 */
photoGallery(pageNum: number, categoryId: number): any {
  return this.http.post<any>(`${this.serverUrl}api/f/photo_gallery`, { page : pageNum, category_id: categoryId }).pipe(map(res => {
      return res;
      })
  );
}

/** comment
 * video gallary
 */
vodeoGallery(pageNum: number): any {
  return this.http.post<any>(`${this.serverUrl}api/f/video_gallery`, { page : pageNum }).pipe(map(res => {
      return res;
      })
  );
}

/** comment
 * get team record
 */
getTeamList(): any {
  return this.http.post<any>(`${this.serverUrl}api/f/team`, {}).pipe(map(res => {
      return res;
      })
  );
}

/** comment
 * get success story
 */
getSuccessStory(pageNum: number): any {
  return this.http.post<any>(`${this.serverUrl}api/f/success_stories`, { page: pageNum }).pipe(map(res => {
      return res;
      })
  );
}

/** comment
 * get testimonials list
 */
getTestimonials(pageNum: number): any {
  return this.http.post<any>(`${this.serverUrl}api/f/testimonials`, { page: pageNum }).pipe(map(res => {
      return res;
      })
  );
}

/** comment
 * get setting
 */
commonSiteSetting() {
  const user = +localStorage.getItem('userid');
  const user_id = ((user)?user:0);
  this.http.post(`${this.serverUrl}api/f/site_settings`, {user_id: user_id}).subscribe(res => {
    this.AdminCommonData.next(res['data']);
  });
}

/** comment
 * get support reasons
 */
getSupportFormReason(): any {
  return this.http.get<any>(`${this.serverUrl}api/f/support_reasons`).pipe(map(res => {
      return res;
      })
  );
}

/** comment
 * get donation reasons
 */
donationReasons(): any {
  return this.http.get<any>(`${this.serverUrl}api/f/donation_reasons`).pipe(map(res => {
      return res;
      })
  );
}

/** comment
 * get upcoming events
 */
upcomingEventsRecord(): any {
  return this.http.get<any>(`${this.serverUrl}api/f/upcoming_events_home`).pipe(map(res => {
      return res;
      })
  );
}

/** comment
 * get setting singleton service
 */
commonSiteSettingSingleton(): any {
  return this.AdminCommonData.asObservable();
}

/** comment
 * home banner
 */
homeBanner(): any {
  return this.http.get<any>(`${this.serverUrl}api/f/home_banner`).pipe(map(res => {
      return res;
      })
  );
}

/** comment
 * get filter list
 */
getTestFilters(): any {
  return this.http.get<any>(`${this.serverUrl}api/f/test_list_filters`).pipe(map(res => {
      return res;
      })
  );
}

getCategoryDetailBySlug(slug: any): any {
  return this.http.get<any>(`${this.serverUrl}api/f/category_by_slug/` + slug).pipe(map(res => {
    return res;
  })
);
}

getTestList(formData: any): any {
  return this.http.post<any>(`${this.serverUrl}api/f/test_list`, formData).pipe(map(res => {
      return res;
      })
  );
}

getPreviousTestList(formData: any): any {
  return this.http.post<any>(`${this.serverUrl}api/f/previous_test_list`, formData).pipe(map(res => {
      return res;
      })
  );
}

}
