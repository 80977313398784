<div class="clearfix"></div>
<footer>
   <div class="container">
      <div class="col-md-3">
         <h3 class="text-white">FAQ</h3>
         <div class="clearfix"></div>
         <br />
         <br />
         <ul class="footer-links">
            <li *ngFor="let category of categories;  let i= index;"><a [routerLink]="['/11plus-help-club/faq',category.slug]"><i class="fa fa-angle-right" aria-hidden="true"></i>{{category.faq_category}}</a></li>
            <li><a [routerLink]="['/support']"><i class="fa fa-angle-right" aria-hidden="true"></i> Support Form</a></li>
         </ul>
      </div>
      <div class="col-md-3">
         <h3 class="text-white">Legal</h3>
         <div class="clearfix"></div>
         <br />
         <br />
         <ul class="footer-links">
            <li><a [routerLink]="['/legal/terms-conditions']"><i class="fa fa-angle-right" aria-hidden="true"></i> Terms & Conditions</a></li>
            <li><a [routerLink]="['/legal/privacy-policy']"><i class="fa fa-angle-right" aria-hidden="true"></i> Privacy Policy</a></li>
            <li><a [routerLink]="['/legal/payment-refund']"><i class="fa fa-angle-right" aria-hidden="true"></i> Payment & Refund</a></li>
            <li><a [routerLink]="['/legal/disclaimers']"><i class="fa fa-angle-right" aria-hidden="true"></i> Disclaimers</a></li>
            <li><a [routerLink]="['/legal/copy-right']"><i class="fa fa-angle-right" aria-hidden="true"></i> Copy Right</a></li>
         </ul>
      </div>
      <div class="col-md-3">
         <h3 class="text-white">Quick Links</h3>
         <div class="clearfix"></div>
         <br />
         <br />
         <ul class="footer-links">
            <li><a [routerLink]="['/']"><i class="fa fa-angle-right" aria-hidden="true"></i> Home</a></li>
            <li><a [routerLink]="['/sehyog/inception-story']"><i class="fa fa-angle-right" aria-hidden="true"></i> Sehyog</a></li>
            <li><a [routerLink]="['/11plus-help-club']"><i class="fa fa-angle-right" aria-hidden="true"></i> 11Plus Help Club</a></li>
            <li><a [routerLink]="['/11plus-help-club/category']"><i class="fa fa-angle-right" aria-hidden="true"></i> Category</a></li>
            <li><a [routerLink]="['/11plus-help-club/get-inspired/success-stories']"><i class="fa fa-angle-right" aria-hidden="true"></i> Success Stories</a></li>
            <li><a [routerLink]="['/contact-us']"><i class="fa fa-angle-right" aria-hidden="true"></i> Contact us</a></li>
         </ul>
      </div>
      <div class="col-md-3">
         <h3 class="text-white">Other Links</h3>
         <div class="clearfix"></div>
         <br />
         <br />
         <ul class="footer-links">
            <li class="hide"><a href="#"><i class="fa fa-angle-right" aria-hidden="true"></i> Make A Donation</a></li>
            <li><a [routerLink]="['/become-a-volunteer']"><i class="fa fa-angle-right" aria-hidden="true"></i> Become A Volunteer</a></li>
            <li><a href="{{siteSetting?.social_telegram_url}}" target="_blank"><i class="fa fa-angle-right" aria-hidden="true"></i>Telegram Group</a></li>
         </ul>
      </div>
   </div>
   <div class="clearfix"></div>
   <br />
   <div class="copyright">
      <div class="container">
         <div class="col-md-6">
            <div class="text-sml">&copy; {{ getCurrentYear() }} sehyog.co.uk All Rights Reserved. Powered by  <a href="https://unicodesolutions.com/" target="_blank" style="color:#fff;">Unicode Solutions</a></div>
         </div>
         <div class="col-md-6">
            <ul class="list list-inline footer-socials">
               <li><a href="{{siteSetting?.social_facebook_url}}" target="_blank" class="fb btn-hover"><i class="fa fa-facebook " aria-hidden="true"></i></a></li>
               <li><a href="{{siteSetting?.social_twitter_url}}" target="_blank" class="tw btn-hover"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
               <li><a href="{{siteSetting?.social_linkedin_url}}" target="_blank" class="linkdin btn-hover"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
               <li><a href="{{siteSetting?.social_telegram_url}}" target="_blank" class="linkdin btn-hover"><i class="fa fa-telegram" aria-hidden="true"></i></a></li>
            </ul>
         </div>
      </div>
   </div>
</footer>
<button class='gototop isvisible'><img src='assets/images/go.png'></button>
<div class="modal fade popup autoload" id="autoload" role="dialog" *ngIf="testPopupCondition">
   <div class="modal-dialog ">
      <!-- Modal content-->
      <div class="modal-content">
         <div class="modal-body padding0">
            <div class="rows overflow" >
               <div class="col-md-5" style="padding-left:0px;">
                  <div class="cycle-slideshow" >
                     <div class="bg">
                        <h2 class="text-white">Get in Touch</h2>
                        <div class="clearfix"></div>
                        <br />
                        <br />
                        <div class="rows">
                           <div class="col-md-1"><i class="fa fa-map-marker" aria-hidden="true"></i></div>
                           <div class="col-md-11">
                              <div class="text">
                                    {{siteSetting?.address}}
                              </div>
                           </div>
                        </div>
                        <div class="rows">
                           <div class="col-md-1"><i class="fa fa-phone" aria-hidden="true"></i></div>
                           <div class="col-md-11">
                              <div class="text">
                                    {{siteSetting?.site_display_contact}}
                              </div>
                           </div>
                        </div>
                        <div class="rows">
                           <div class="col-md-1"><i class="fa fa-envelope" aria-hidden="true"></i></div>
                           <div class="col-md-11">
                              <div class="text">
                                    {{siteSetting?.site_display_email}}
                              </div>
                           </div>
                        </div>
                        <div class="rows overflow-none">
                           <ul class="list list-inline footer-socials">
                              <li><a href="{{siteSetting?.social_facebook_url}}" target="_blank" class="fb btn-hover"><i class="fa fa-facebook " aria-hidden="true"></i></a></li>
                              <li><a href="{{siteSetting?.social_twitter_url}}" target="_blank" class="tw btn-hover"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                              <li><a href="{{siteSetting?.social_linkedin_url}}" target="_blank" class="linkdin btn-hover"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-7">

                     <div class="form-overlay">
                           <div class="overlay">

                              <div class="text text-center" *ngIf="formMessage != ''"><img src="assets/images/verified.svg" width="30"> {{formMessage}}
                                 <!-- <div class="clearfix"></div><br> -->
                                 </div>
                              </div>

                  <h3>Please fill the following form</h3>
                  <button type="button" class="close closeGetIntouch" data-dismiss="modal"><i class="ti-close" aria-hidden="true"></i></button>
                  <form id="contact_enquiry" class="parent" [formGroup]="getInTouch" (ngSubmit)="onSubmit()" ngNativeValidate>
                     <div ></div>
                     <div class="loader-bg get-touch">
                           <img src="assets/images/spiner.png">
                     </div>

                     <div class="loader-bg error">
                           <div class="text text-center">Captcha is required</div>
                     </div>

                     <br>
                     <ul>
                        <li>
                           <input type="text" formControlName="name" placeholder="Enter your Name" maxlength="25" class="transition form-control" required>
                        </li>
                        <li>
                           <input type="email" formControlName="email_id" placeholder="Enter your Email Id" maxlength="40" class="transition form-control" required >
                        </li>
                        <li>
                           <input type="text" formControlName="phone" placeholder="Enter your Mobile No" id="mob" class="transition form-control uphone" maxlength="15" required>
                        </li>
                        <li>
                           <textarea  formControlName="message" placeholder="Message" maxlength="250" class="transition form-control" required></textarea>
                        </li>
                        <li><ngx-recaptcha2  [siteKey]="googleKey" formControlName="recaptcha" ></ngx-recaptcha2>
                           <div class="clearfix"></div>
                           <br></li>
                        <li><label></label><button type="submit" name="submit" class="btn btn-custom org btn-hover">SUBMIT </button></li>
                     </ul>
                     <div class="clearfix"></div>
                     <br>
                  </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<div class="cookies-bar bg-blue transition" *ngIf="!cookieValue">
   <div class="container">
      <div class="col-md-9">
         <h3 class="text-white">This website uses cookies. By continuing to browse the site, you are agreeing to our <a href="#">use of cookies</a> </h3>
      </div>
      <div class="col-md-3">
         <a (click)="setCookies()" class="btn btn-custom white btn-hover">allow cookies</a>
      </div>
   </div>
</div>



