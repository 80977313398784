<div class="clearfix"></div>

<div class="tp-hd">

  <app-header ></app-header> 

  <div class="top" >
  

    <p> <strong>SEHYOG Academy</strong><br> Tutoring & Mock Exams | UK & UAE </p>
  
  
  <div class="trust-pilot">
  
    <img src="assets/images/trust-pilot.png" alt="ratings">
  
  </div>
    
  </div>

  <div class="nw-strp">

    <div class="nw-strp-lft">
      <h5>Sehyog Classes:</h5>
        <a class="bk-dm" href="https://www.sehyog.co.uk/freedemo">Book Free Demo</a>
    </div>

    <div class="nw-strp-rgt">
      <h5>Also Visit: </h5>
        <a class="to-nmt" [routerLink]="['/gcse-mocktest']">GCSE Mock Test</a>
    </div>

  
  </div>

</div>




<div class="hero-section">

  <div class="hs-head">
    <h1 class="hs-h1">11+ MOCK TEST</h1>
  </div>

  <h3>CSSE | FSCE | GL REDBRIDGE | GL KENT | GL BEXLEY | GL QE | GL HBS | BUCKS</h3>

  <h4>FACE TO FACE | ONLINE | PAPER AT HOME</h4>



  <h1 class="hs-h2">PASS YOUR <span style="color: #f38d19;">11+ EXAM</span> WITH CONFIDENCE</h1>
  <h2>Signup for our specially designed 11+ Mock Tests!</h2>

  <div class="stripe">
    <H2 style="font-weight: bold;">MOCK TEST CALENDER</H2>
  </div>

  <h3>SELECT THE DATE/CENTER BELOW & CLICK BOOK BUTTON</h3>

</div>

<div class="container">

  <div class="test-menu">

    <a class="btn-tab" [routerLink]="['/topic-test']">Topic Test</a>
    <a class="btn-tab active" [routerLink]="['/11Plus-mocktest']">Mock Test</a>
    <a class="btn-tab" [routerLink]="['/yearfour-topictest']">Year 04 Topic Test</a>
  </div>

  <div class="tabs">
    <div 
      *ngFor="let month of months"
      class="tab" 
      [ngClass]="{active: selectedMonth === month}"
      (click)="selectMonth(month)">
      {{ month }}
    </div>

</div>

  <div class="timetable-wrapper">
    <div class="timetable">
      <div 
        *ngFor="let test of timetable[selectedMonth]"
        class="test-row">
        <div class="test-info enhanced">
          <div class="test-date">{{ test.date }}</div>
          <h3 class="testName">{{ test.name }}</h3>
          <p class="testTopic">{{ test.testtype }}</p>
          <p class="testTopic">{{ test.description }}</p>
        </div>
        <div class="test-centers">
          <div 
            *ngFor="let center of test.centers"
            class="center"
            [ngClass]="{'online': center.name === 'Online'}">
            <p class="center-name">{{ center.name }}</p>
            <p>{{ center.time }}</p>
            <a class="book-btn" [href]="center.url" target="_blank">Book</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="af-ts">

  <h1>With more than a decade of experience we’ve helped <br>
    <strong>10,000+</strong> students improve their scores & ace their <strong>11plus</strong>!</h1>

</div>

<div class="ft-sc">

  <h1>What's Included in Your Mock Test?</h1>

  <div class="ft-sc-info">
    <div class="ft-sc-item left">
      <h3>Real Exam Like Environment</h3>
      <p>Timed & structured like actual 11plus Exams</p>
    </div>

    <div class="ft-sc-item">
      <h3>Results Analysis Report (RAR)</h3>
      <p>Paper reviewed & marked with comments by 11plus specialist</p>
    </div>

    <div class="ft-sc-item right">
      <h3>Take Your Test Paper Home</h3>
      <p>Review your errors & mistakes</p>
    </div>
  </div>

  <div class="ft-sc-info">
    <div class="ft-sc-item left">
      <h3>Covers CSSE, FSCE, GL, SET patterns</h3>
      <p>Tailored to your exam board</p>
    </div>

    <div class="ft-sc-item">
      <h3>Instant & Detailed Feedback</h3>
      <p>Identify strengths & weaknesses</p>
    </div>

    <div class="ft-sc-item right">
      <h3>Consulting & Support </h3>
      <p>Post exam guidance & support from 11plus specialists</p>
    </div>

  </div>

</div>


<div class="co-sc">

  <h1>Join the Community of <br> <span>10K+ Successful Students & Happy Parents!</span></h1>

  <div class="ts-sc">

    <div class="card-ts">

      <h4 style="font-weight: bold;">Jaya Rani</h4>

      <p style="text-align: justify;"> My son did Sehyog Mock Test, it’s very helpful to know which areas my son is lacking. Sehyog result analysis gives us understanding on which area the aspirant/parent needs to improve and concentrate on, which is very important in 11 plus journey, apart from that Sehyog also provides you guidance on how to submit CAF form with the school preferences and clarified most of our doubts. Thank you so much Sehyog team for your all your efforts.</p>


    </div>

    <div class="card-ts">

      <h4 style="font-weight: bold;">harita nagula</h4>

      <p style="text-align: justify;">  Sheyog Mocks really helped my Twins to secure a place in ICHS . Mock exams made my boys have more confidence and follow the timings and instructions. I would recommend to write sheyog mocks to get a real-time experience for the main exam. Thank you Sheyog team for giving us free sessions as I am an NHS staff .
      </p>

      
    </div>

    <div class="card-ts">

      <h4 style="font-weight: bold;">Mini Dagar</h4>

      <p style="text-align: justify;"> My son attended the mock exams by 11plushelpclub and it helped us a lot. With the detailed results every week, we knew which area to work on. Topic wise tests were a great form of revision. All the help by whole team was fabulous which helped my son to secure a safe place at school of his choice. Big thanks to everyone out there for all the help and guidance - Mini Dagar 
      </p>
      
    </div>
    

  </div>

</div>

<div class="faq-sc">

  <h1>FAQs</h1>

  <div class="drop-d">

    <details>
      <summary>How do I book my 11plus Mock Test?</summary>
      <div>
          <p>Select Center and Date on the 11plus Mock Test Calendar and Simply click "Book Now" </p>
          
      </div>
  </details>
  
  <details>
    <summary>Are these tests online or face-to-face?</summary>
    <div>
        <p>We offer both online & offline mock tests. </p>
    </div>
  </details>
  
  <details>
    <summary>Which exam boards do you cover?</summary>
    <div>
        <p>GL, CSSE, FSCE, SET, ISEB – All covered!</p>
    </div>
  </details>

  </div>


  <h3 style="text-align: center; margin-top: 50px;">Still have questions? <br>
    Connect Directly on <strong style="font-weight: bold;">+44 7464772331</strong> & EMAIL <strong style="font-weight: bold;">academy@sehyog.co.uk</strong></h3 >


    <div class="so-sc">

      <img src="assets/images/linkedin.png" alt="linkedin">
      <img src="assets/images/facebook.png" alt="linkedin">
      <img src="assets/images/youtube copy.png" alt="linkedin">
      <img src="assets/images/twitter.png" alt="linkedin">
      <img src="assets/images/instagram.png" alt="linkedin">

    </div>


    <p style="width: 80%; margin-top: 100px; text-align: justify;">SEHYOG is an educational institute dedicated to providing comprehensive support to KS2, 11plus, KS3, 11plus & A-Level students to achieve excellence in education and personal development with honesty and transparency. The sessions, workshops/webinars conducted by SEHYOG do not guarantee 100% success in the real exams. No claim is made as to the accuracy or authenticity of the content of the sessions, workshops/webinars or the information shared via email, website, social media etc. SEHYOG or its volunteers/staff do not accept any liability to any person or firm for the information or advice (or the use of such information or advice) which is provided in the sessions/workshops, email or on social media. The information on the website, phone, emails, social media and sessions/workshops is provided on the basis that all persons accessing the information undertake responsibility for assessing the relevance and accuracy of its content.</p>

 

</div>

