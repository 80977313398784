<div class="clearfix"></div>


  <!-- Slider section starts here -->
  <div class="slider-container">
    <div
      *ngFor="let slide of slides; let i = index"
      class="slide"
      [class.active]="i === currentSlide"
      (click)="onSlideClick(i)"
    >
      <img [src]="slide.image" [alt]="'Slide ' + (i + 1)" />

      <!-- Show Buttons Only for First & Second Slide -->
  </div>

    </div>


  <h4 style="align-self: center">Click the banner to learn more</h4>

  <!-- slider section ends here -->

  <!-- strip starts here -->

  <div class="strp-1">
    <div class="strp-cnt">
      <h1>10K+</h1>
      <p>Students World Wide</p>
    </div>

    <div class="line-v"></div>

    <div class="strp-cnt">
      <h1>15+</h1>
      <p>Subjects</p>
    </div>

    <div class="line-v"></div>

    <div class="strp-cnt">
      <h1>25+</h1>
      <p>Highly Qualified Tutors</p>
    </div>

    <div class="line-v"></div>

    <div class="strp-cnt">
      <h1>20+</h1>
      <p>Focused Boards</p>
    </div>

    <div class="line-v"></div>

    <div class="jwhatsapp" (click)="openWhatsApp()">
      <div class="jwa-cnt">
        <div class="jwa-cnt-sub">
          <h3>JOIN</h3>
          <img src="assets/images/logo-w-aca.png" alt="" />
          <h3>ON</h3>
        </div>

        <h2>WhatsApp</h2>
      </div>

      <img class="wa-img" src="assets/images/wa-img.png" alt="" />
    </div>
  </div>

  <!-- strip ends here -->

  <!-- explore starts here -->

  <div class="exp-sec">
    <div class="exp-sub1">
      <div class="exp-sub2">
        <div class="exp-head">
          <h1>Explore</h1>
          <img src="assets/images/logo-w-aca.png" alt="" />
        </div>

        <button class="cta-1" routerLink = '/freedemo'>Book a Free Demo</button>
      </div>

      <div class="exp-crd-sec">
        <div class="exp-card" [routerLink]="['/11Plus-classes']">
          <img src="assets/images/elevp_tt.jpg" alt="" />
          <h4>11plus Tutoring</h4>
        </div>

        <div class="exp-card" [routerLink]="['/gcse-classes']">
          <img src="assets/images/gcse_tt.jpg" alt="" />
          <h4>GCSE Tutoring</h4>
        </div>
      </div>
    </div>

    <div class="exp-crd-sec" [routerLink]="['/11Plus-mocktest']">
      <div class="exp-card">
        <img src="assets/images/eplus.jpg" alt="" />
        <h4>11plus Mocks</h4>
      </div>

      <div class="exp-card" [routerLink]="['/gcse-mocktest']">
        <img src="assets/images/gcse_mks.jpg" alt="" />
        <h4>GCSE Mocks</h4>
      </div>

      <div class="exp-card" [routerLink]="['/product-services']">
        <img src="assets/images/cc_img.png" alt="" />
        <h4>Crash Course</h4>
      </div>

      <div class="exp-card">
        <img src="assets/images/consort_1.png" alt="" />
        <h4>11plus Consortiums</h4>
      </div>
    </div>
  </div>

  <!-- explore ends here -->

  <!-- section 3 starts here -->

  <div class="section-3">
    <div class="sec3-sub1">
      <div class="sec3-sub2">
        <h1>
          Why we are <br />
          best from others
        </h1>
        <p>Providing exceptional service in the UK for the past 10 years, now expanding to the UAE.</p>
        <button class="cta-2" routerLink = '/freedemo'>Book a Free Demo</button>
      </div>

      <div class="sec3-card-sec top">
        <div class="sec3-card">
          <h3>Best Tutors</h3>
          <p>
            Good tutors teach you, but the best tutors prepare you for the
            future.They don't just give knowledge; they shape your skills for
            success.
          </p>

          <img src="assets/images/b1.png" alt="" />
        </div>

        <div class="sec3-card">
          <h3>Best Strategies</h3>
          <p>
            A good strategy educates you, but the best strategy prepares you well
            for the real exams. It goes beyond books, shaping skills and mindset
            for lifelong success.
          </p>

          <img src="assets/images/b2.png" alt="" />
        </div>
      </div>
    </div>

    <div class="sec3-card-sec">
      <div class="sec3-card">
        <h3>Awards Sessions</h3>
        <p>
          A good award session recognizes achievements, but the best award
          session inspires future excellence. It not only celebrates success but
          also motivates everyone to reach greater heights.
        </p>

        <img src="assets/images/b3.png" alt="" />
      </div>

      <div class="sec3-card">
        <h3>Best Practice</h3>
        <p>
          Good practices improve you, but the best practices transform you. They
          don’t just build skills; they create habits for lifelong success.
          Consistent effort, discipline, and the right approach turn small steps
          into great achievements.
        </p>

        <img src="assets/images/b4.png" alt="" />
      </div>

      <div class="sec3-card">
        <h3>Best Price</h3>
        <p>
          Good prices save you money, but the best prices give you true value.
          They don’t just cut costs; they ensure quality and smart spending.
          Balancing affordability and excellence turns every purchase into a
          wise investment.
        </p>

        <img src="assets/images/b5.png" alt="" />
      </div>
    </div>
  </div>

  <!-- section 3 ends here -->

  <!-- section 4 starts here -->

  <div class="section-4">
    <h1>Our Most Popular Servings</h1>

    <button class="cta-1" routerLink = '/product-services'>Products & Services</button>

    <div class="sec4-cards-sec">
      <div class="sec4-card">
        <img src="assets/images/ps1.jpg" alt="" />

        <h3>Online Tutoring</h3>
        <p>11Plus | GCSE | A Level</p>

        <button class="cta-3" routerLink = "/gcse-classes">View Courses</button>
      </div>

      <div class="sec4-card">
        <img src="assets/images/ps3.jpg" alt="" />

        <h3>Mock Assessments</h3>
        <p>Face-2-Face & Online</p>

        <button class="cta-3" routerLink = "/11Plus-mocktest">View Calendar</button>
      </div>

      <div class="sec4-card">
        <img src="assets/images/ps2.jpg" alt="" />

        <h3>Crash Courses</h3>
        <p>Short-term, high-impact sessions.</p>

        <button class="cta-3"  routerLink = "/product-services" >View Courses</button>
      </div>
    </div>
  </div>

  <!-- section 4 ends here -->

  <!-- section 5 starts here -->


  <section class="upcoming-event" *ngIf="images?.length > 0">
   <div class="container">
      <div class="text-center">
         <h2 class="custom-hd text-center">Upcoming <label>Sessions</label> <span></span></h2>
      </div>

      <!-- updated on 02-03-24 -->
      <!-- <div class="col-md-4 text-center" *ngFor="let category of categoryListing">
         <a
            [routerLink]="['/11plus-help-club/category',category.category_slug]" class="btn btn-custom org btn-hover goto"><i
                  class="fa fa-caret-right" aria-hidden="true"></i> {{category.category_name}}</a>
      </div> -->
      <!-- end updation -->
      <div class="clearfix"></div>
      <br />
      <br />

      <div class="row upcoming-events upcoming-event-section events">

         <owl-carousel [options]="{items: 3, dots: false, navigation: false, responsive: { '0': {  items: 1}, '480': { items: 1},
            '1200': { items: 3}  } }" [items]="images" [carouselClasses]="['owl-theme', 'sliding']">
            <div class="col-md-4 item" *ngFor="let image  of images">
               <a [routerLink]="['/11plus-help-club/category/',image.category_slug,image.test_slug]"
                  routerLinkActive="router-link-active" class="goto">
                  <div class="img">
                     <img src="{{image?.test_image}}" class="full transition" />
                     <div class="overlay transition"><i class="fa fa-link transition" aria-hidden="true"></i></div>
                  </div>
               </a>
               <h2>
                  <a  [routerLink]="['/11plus-help-club/category/',image.category_slug,image.test_slug]" class="goto">{{image?.test_name }}</a>
               </h2>
               <h4 *ngIf="image?.test_location == ''"><img src="assets/images/map1.png" /> <span class="badge badge-danger">Online Session</span></h4>
               <h4 *ngIf="image?.all_location == '' && image?.test_location != ''"><img src="assets/images/map1.png" /> Location: {{image?.test_location }} </h4>
               <h4 class="tooltip-parent" *ngIf="image?.all_location != ''">
                  <img src="assets/images/map1.png" /> Location: {{image?.test_location }} 
                  <div class="tooltip">
                     <span>{{image?.all_location}}</span>
                  </div> 
               </h4>
               <span class="date transition" *ngIf="image?.test_date != ''"><img src="assets/images/date.png" />{{image.test_date}}</span>
               <div class="clearfix"></div>
               <div class="multiple-location"><img src="assets/images/multiple-location.png" /> <a href="#"
                     class="border-effect transition a-link">View more location</a></div>
            </div>
         </owl-carousel>



      </div>


   </div>

   </section>

  

  <!-- section 5 end here -->

  <!-- section 6 starts here -->

  <div class="section-6">
    <h1>Gallery</h1>
    <h3>TAKE A LOOK - WHERE WE HAVE BEEN UP TO RECENTLY</h3>

    <div class="gallery-container">
      <div class="gal-left">
        <img
          [src]="currentImage"
          alt="Event Image"
          class="large-image"
          [class.zoom]="isSliding"
        />
      </div>

      <div class="gal-right">
        <h1 class="img-title">{{ currentTitle }}</h1>

        <div class="btn-div">
         <button class="gal-btn" [routerLink]="['/sehyog/photo-gallery']">View Photos</button>
         <button class="gal-btn sd" [routerLink]="['/sehyog/video-gallery']">View Videos</button>
        </div>

        <div class="gal-thumb">
          <img
            *ngFor="let img of gal_images; let i = index"
            [src]="img.src"
            (click)="changeImage(i)"
            [class.active]="i === currentIndex"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- section 6 ends here -->


  <!-- section 7 starts here -->

  <div class="section-7">

   <h1>Who We Are?</h1>

   <div class="sec7-sub">

      <div class="sec7-sub-left">

         <img src="assets/images/slide-3-img.jpg" alt="">

         <button class="cta-1" routerLink = '/freedemo'>Book a Free Demo</button>

      </div>

      <div class="sec7-sub-right">
         <h4>SEHYOG Group</h4>

         <p>SEHYOG is an educational institution dedicated to providing comprehensive support to students at various stages, including KS2, 11plus, CAT4, 12+, 13+, KS3, GCSE/iGCSE, and A-Level. Our goal is to help students excel across major UK (and international) exam boards such as GL, CSSE, FSCE, SET, ISEB, CAIE, AQA, OCR, Edexcel, and EDUQAS. This initiative aims to empower youth education and offer support to underprivileged communities where needed, both in the UK and internationally. We believe that young people should not only have access to quality education but also meaningful real-world experiences that foster a strong vision, boosting their self-confidence and self-belief.<br>
            <br>

 
            <strong>Journey..</strong> <br>
            SEHYOG started its journey in 2014/15 as a small 11plus HELP group in East London, focusing on grassroots education, quality, transparency, and consistent support for both students and parents in securing placements at their preferred grammar or private schools. Since then, SEHYOG has grown rapidly, expanding across the UK and internationally, including the launch of its Academy wing, which offers comprehensive support for students from Year 03 to Year 13. We have built a strong track record of success in 11plus, GCSE, and A Level exams across the UK. Our team of experienced teachers has created a thorough process to cover the full 11plus, GCSE, and A Levels curriculums. We take pride in having supported over 10,000 students since the 2014/15 academic year.</p>

      </div>

   </div>

  </div>


  <!-- section 7 ends here -->


  <!-- section 8 starts here -->

  <div class="section-8">


   <h1>What Parents Say About Us?</h1>

   <div class="sec-4">

    
      <div class="cs-sec">
    
        <div class="cs-crd">
          <div class="crd-cir">
            <h4 style="margin: 0px;">JS</h4>
          </div>
          <div class="info">
            <p style="font-weight: bold;">Jaya Rani</p>
            <p style="text-align: justify;">My son did Sehyog Mock Test, it’s very helpful to know which areas my son is
              lacking. Sehyog result analysis gives us understanding on which area the aspirant/parent needs to improve and
              concentrate on, which is very important in 11 plus journey, apart from that Sehyog also provides you guidance
              on how to submit CAF form with the school preferences and clarified most of our doubts. Thank you so much
              Sehyog team for your all your efforts.</p>
    
          </div>
        </div>
    
        <iframe  src="https://www.youtube.com/embed/3PjPMOdyV-A"
          title="Happy Parents Speak: Success Stories &amp; Testimonials 01" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    
      </div>
    
      <!-- Testimonial Ends -->
    
      <div class="cs-sec">
    
    
        <iframe src="https://www.youtube.com/embed/fVU0xDgiclA" title="Happy Parents Speak: Success Stories &amp; Testimonials 02" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    
        <div class="cs-crd">
          <div class="crd-cir">
            <h4 style="margin: 0px;">MD</h4>
          </div>
          <div class="info">
            <p style="font-weight: bold;">Mini Dagar</p>
            <p style="text-align: justify;">My son attended the mock exams by 11plushelpclub and it helped us a lot. With
              the detailed results every week, we knew which area to work on. Topic wise tests were a great form of
              revision. All the help by whole team was fabulous which helped my son to secure a safe place at school of his
              choice. Big thanks to everyone out there for all the help and guidance - Mini Dagar</p>
    
          </div>
        </div>
    
      </div>
    </div>
    
    <button class="cta-2" routerLink = '/freedemo'>Book a Free Demo</button>


  </div>



