var WebinarComponent = /** @class */ (function () {
    function WebinarComponent() {
        this.showCalendar = false;
        this.modalState = 'closed';
        this.activeTab = 'upcoming';
        this.events = [
            { date: 'March 15', title: "GCSE Math's Mastery: ", time: "07:00PM", type: "upcoming", link: "https://teams.microsoft.com/l/message/19:d0112af2-3380-4f2f-a9a6-ab362e38fd64_e09fe10a-7f84-4321-bf16-21d9803a7e8c@unq.gbl.spaces/1741102051364?context=%7B%22contextType%22%3A%22chat%22%7D" },
            { date: 'March 28', title: "GCSE English Language Secrets", time: "07:00PM", type: "upcoming", link: "https://teams.microsoft.com/l/message/19:d0112af2-3380-4f2f-a9a6-ab362e38fd64_e09fe10a-7f84-4321-bf16-21d9803a7e8c@unq.gbl.spaces/1741102051364?context=%7B%22contextType%22%3A%22chat%22%7D" },
            { date: 'April 11', title: "GCSE ENGLISH WEBINAR", time: "07:00PM", type: "upcoming", link: "https://teams.microsoft.com/l/message/19:d0112af2-3380-4f2f-a9a6-ab362e38fd64_e09fe10a-7f84-4321-bf16-21d9803a7e8c@unq.gbl.spaces/1741102051364?context=%7B%22contextType%22%3A%22chat%22%7D" },
            { date: 'April 25', title: "ACE GCSE SCIENCE", time: "07:00PM", type: "upcoming", link: "https://teams.microsoft.com/l/message/19:d0112af2-3380-4f2f-a9a6-ab362e38fd64_e09fe10a-7f84-4321-bf16-21d9803a7e8c@unq.gbl.spaces/1741102051364?context=%7B%22contextType%22%3A%22chat%22%7D" },
        ];
    }
    Object.defineProperty(WebinarComponent.prototype, "filteredEvents", {
        get: function () {
            var _this = this;
            return this.events.filter(function (event) { return event.type === _this.activeTab; });
        },
        enumerable: true,
        configurable: true
    });
    WebinarComponent.prototype.setActiveTab = function (tab) {
        this.activeTab = tab;
    };
    // Open with animation
    WebinarComponent.prototype.openCalendar = function () {
        var _this = this;
        this.showCalendar = true;
        setTimeout(function () {
            _this.modalState = 'open';
        }, 10);
    };
    // Close with animation
    WebinarComponent.prototype.closeCalendar = function () {
        var _this = this;
        this.modalState = 'closed';
        setTimeout(function () {
            _this.showCalendar = false;
        }, 200); // Match the closing animation duration
    };
    return WebinarComponent;
}());
export { WebinarComponent };
