import { Component, OnInit } from '@angular/core';
import { TimetableService } from '../services/year-four.data.service';
import { MonthTimetable } from '../models/event.model';


@Component({
  selector: 'app-yearfour',
  templateUrl: './yearfour.component.html',
  styleUrls: ['./yearfour.component.css']
})
export class YearfourComponent implements OnInit {
months: string[] = [];
  selectedMonth: string = 'April';
  timetable: MonthTimetable = {};

  constructor(private timetableService: TimetableService) {}

  ngOnInit() {
    this.months = this.timetableService.getMonths();
    this.timetable = this.timetableService.getTimetable();
  }

  selectMonth(month: string) {
    this.selectedMonth = month;
  }

}
