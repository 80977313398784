import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CmsserviceService } from "src/app/common-cms-service/cmsservice.service";

@Component({
  templateUrl: './academy.component.html',
  styleUrls: ['./academy.component.css']
})
export class AcademyComponent implements OnInit {

  slug: string;
  pagetitle: string;
  fields = [];
  ngUnsubscribe = new Subject<void>();

  constructor( private router: Router, private cmsService: CmsserviceService, private titleService: Title) {}

  ngOnInit(): void {
    this.slug = this.router.url;
    this.onLoad();
  }

  onLoad() {
    $('.loader-bg').fadeIn();
    const pageName = this.slug.split('/')[(this.slug.split('/').length - 1)];
    this.cmsService.getCmsData(pageName)
    .pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
    this.pagetitle = res.data.page_title;
    this.fields = res.data.fields;
    this.titleService.setTitle(this.pagetitle);
    $('.loader-bg').fadeOut();
});
}


}
