import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';


@Component({
  selector: 'app-product-services',
  templateUrl: './product-services.component.html',
  styleUrls: ['./product-services.component.css']
})
export class ProductServicesComponent implements OnInit, OnDestroy {

  slides = [0, 1, 2]; // Number of slides
  activeIndex = 0;
  interval: any;
  intervalTime = 5000; // 5 seconds

  ngOnInit() {
    
  }

  ngOnDestroy() {
    
  }

  startAutoPlay() {
    this.interval = setInterval(() => {
      this.nextSlide();
    }, this.intervalTime);
  }

  stopAutoPlay() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  nextSlide() {
    this.activeIndex = (this.activeIndex + 1) % this.slides.length;
  }

  previousSlide() {
    this.activeIndex = (this.activeIndex - 1 + this.slides.length) % this.slides.length;
  }

  goToSlide(index: number) {
    this.activeIndex = index;
  }

  onMouseEnter() {
    //this.stopAutoPlay();
  }

  onMouseLeave() {
    this.startAutoPlay();
  }

  openWhatsApp() {
    window.open('https://wa.me/447464772331', '_blank');
  }

}
