import { MonthTimetable } from "../models/event.model";
import * as i0 from "@angular/core";
var TimetableService = /** @class */ (function () {
    function TimetableService() {
        this.timetableData = {
            April: [
                {
                    date: "Sat 05",
                    name: "Topic Test",
                    description: " M01 Maths Numbers, Factors",
                    testtype: "ECOMP01 (GL) - 1st English Comprehension",
                    centers: [
                        {
                            name: "Chelmsford",
                            time: "01:00PM - 03:00PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/2aaf596a74d9b45758549fd2d4849f90",
                        },
                    ],
                },
                {
                    date: "Sun 06",
                    name: "Topic Test",
                    description: "M01 Maths Numbers, Factors",
                    testtype: "ECOMP01 (GL) - 1st English Comprehension",
                    centers: [
                        {
                            name: "Ilford",
                            time: "05:30 PM - 07:30 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/e1ca1a03fa4eb807fa89a7e2353fb7f1",
                        },
                        {
                            name: "Basildon",
                            time: "04:00 PM - 6:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/e5f0bccfa8d8e5abb5b229889726e870",
                        },
                        {
                            name: "Southend-on-Sea",
                            time: "09:30PM - 11:30PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/3daf4c234fcfb8cd0357058372595906"
                        },
                        {
                            name: "Online",
                            time: "9:00 AM - 6:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/218d86ce2079bd5b9a411b225bb287d6",
                        },
                    ],
                },
                {
                    date: "Sun 13",
                    name: "Topic Test",
                    testtype: "M02 Math's Decimal",
                    description: "ECOMP02 (CSSE) - 2nd English Comprehension",
                    centers: [
                        {
                            name: "Ilford",
                            time: "05:30 PM - 07:30 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/a55f3333100139447856e39feb81e455",
                        },
                        {
                            name: "Amersham",
                            time: "03:00 PM - 05:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/b7e695714791662d1b9f5208a487ef9d",
                        },
                        {
                            name: "Bexleyheath",
                            time: "12:00PM - 02:00PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/9516633789c8186328ec51d7ca8bfde7"
                        },
                        {
                            name: "Online",
                            time: "9:00 AM - 6:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/6ea4fb3613ffaacc90bfff4c953b6139",
                        },
                    ],
                },
                {
                    date: "Sat 26",
                    name: "Topic Test",
                    testtype: "M03 Math's Fractions",
                    description: "VRNVR01 - 1st Verbal Non-Verbal Reasoning & CW01",
                    centers: [
                        {
                            name: "Chelmford",
                            time: "01:00 PM - 03:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/7555396d5ecd61e4575867b03dfd7907",
                        },
                    ],
                },
                {
                    date: "Sat 27",
                    name: "Topic Test",
                    testtype: "M03 Math's Fractions",
                    description: "VRNVR01 - 1st Verbal Non-Verbal Reasoning & CW01",
                    centers: [
                        {
                            name: "Illford",
                            time: "05:30 PM - 07:30 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/2ea70400b91cb952242b8dc057c10212",
                        },
                        {
                            name: "Basildon",
                            time: "04:00 PM - 06:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/cc89cd4b0fd03207c646ec9c6202ec81",
                        },
                        {
                            name: "Southend-on-Sea",
                            time: "09:30PM - 11:30PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/2e8fdaed0e974ddb20b27954a69cb975"
                        },
                        {
                            name: "Online",
                            time: "9:00 AM - 6:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/8a02e6eaab1c563dae80fec01f9da8d1",
                        },
                    ],
                },
            ],
            May: [
                {
                    date: "Sat 03",
                    name: "Topic Test",
                    description: "M04 Math's Percentage",
                    testtype: "ECOMP03 (GL) - 3rd English Comprehension",
                    centers: [
                        {
                            name: "Chelmsford",
                            time: "01:00 PM - 03:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/038748d6b87f4b0caf4a7ca8899610bb",
                        },
                    ],
                },
                {
                    date: "Sun 04",
                    name: "Topic Test",
                    description: "M04 Math's Percentage",
                    testtype: "ECOMP03 (GL) - 3rd English Comprehension",
                    centers: [
                        {
                            name: "Ilford",
                            time: "05:30 PM - 07:30 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/b27b0b74bf8b474726826f9e70a496da",
                        },
                        {
                            name: "Basildon",
                            time: "04:00 PM - 06:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/8795368d41fb1983d16428edb81fb64d",
                        },
                        {
                            name: "Amersham",
                            time: "3:00 PM - 05:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/89cf34856bd33713ee1d987703aca34f",
                        },
                        {
                            name: "Online",
                            time: "9:00 AM - 6:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/32e76b70b4cb9ee325e6915f120c849d",
                        },
                    ],
                },
                {
                    date: "Sun 04",
                    name: "MR01",
                    description: "1st Math's Revision",
                    testtype: "",
                    centers: [
                        {
                            name: "Online",
                            time: "09:00 PM - 06:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/f449fec9679f6909ad182b9d48bf9013",
                        },
                    ],
                },
                {
                    date: "Sat 10",
                    name: "Topic Test",
                    description: "M05 Math's Money",
                    testtype: "ECOMP04 (CSSE) - 4th English Comprehension",
                    centers: [
                        {
                            name: "Chelmsford",
                            time: "01:00 PM - 03:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/e8c664f79f3309db333048b15f93e20a",
                        },
                    ]
                },
                {
                    date: "Sun 11",
                    name: "Topic Test",
                    description: "M05 Math's Money",
                    testtype: "ECOMP04 (CSSE) - 4th English Comprehension",
                    centers: [
                        {
                            name: "Ilford",
                            time: "05:30 PM - 07:30 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/353ece1042c1412f658fc1e1ac04b561",
                        },
                        {
                            name: "Basildon",
                            time: "04:00 PM - 06:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/f5569028d235f60a8b341565fcb45a9c",
                        },
                        {
                            name: "Southend-on-Sea",
                            time: "09:30 PM - 11:30 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/79fae6f89b5c1ed047cc6292e6fe185a",
                        },
                        {
                            name: "Online",
                            time: "9:00 AM - 6:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/3c6db2343a945d5015cbbbe1269eb74f",
                        },
                    ],
                },
                {
                    date: "Sun 18",
                    name: "M06",
                    description: "Math's Data Handling Part 1",
                    testtype: "VRNVR02 - 2nd Verbal Non-Verbal Reasoning & CW02",
                    centers: [
                        {
                            name: "Illford",
                            time: "05:30 AM - 7:30 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/a1a207f9bed0b637766be49c42bf3cbc",
                        },
                        {
                            name: "Amersham",
                            time: "03:00 AM - 05:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/e1c9cdd85480f4e566b231ee6f33c74a",
                        },
                        {
                            name: "Bexleyheath",
                            time: "12:00 AM - 02:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/c2e875abebde68b7676a36d13d47186e",
                        },
                        {
                            name: "Online",
                            time: "9:00 AM - 6:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/9be51202d2667190239bdbaae08e5581",
                        },
                    ],
                },
                {
                    date: "Sat 24",
                    name: "Topic Test",
                    description: "M07 Maths Day, Date and Time",
                    testtype: "ECOMP05 (GL) - 5th English Comprehension",
                    centers: [
                        {
                            name: "Chelmsford",
                            time: "01:00 PM - 03:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/ff8147ef749dacac96c3aee4e1fa404e",
                        },
                    ]
                },
                {
                    date: "Sun 25",
                    name: "Topic Test",
                    description: "M07 Maths Day, Date and Time",
                    testtype: "ECOMP05 (GL) - 5th English Comprehension",
                    centers: [
                        {
                            name: "Illford",
                            time: "05:30 PM - 7:30 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/a9983a7d59457b33eafeac03357545fe",
                        },
                        {
                            name: "Amersham",
                            time: "03:00 PM - 05:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/551643d76629dee65b191a024671b282",
                        },
                        {
                            name: "Basildon",
                            time: "04:00 PM - 06:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/e05c7fe538989c78655221fafd7c3049",
                        },
                        {
                            name: "Online",
                            time: "9:00 AM - 6:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/6d94b569d831df67fb0e31e27a976944",
                        },
                    ],
                },
            ],
            June: [
                {
                    date: "Sat 03",
                    name: "Topic Test",
                    description: "M04 Math's Percentage",
                    testtype: "ECOMP03 (GL) - 3rd English Comprehension",
                    centers: [
                        {
                            name: "Chelmsford",
                            time: "01:00 PM - 02:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/0ef9460c460645560f5bbbc1b3257ad7",
                        },
                    ],
                },
                {
                    date: "Sun 04",
                    name: "Topic Test",
                    description: "M04 Math's Percentage",
                    testtype: "ECOMP03 (GL) - 3rd English Comprehension",
                    centers: [
                        {
                            name: "Ilford",
                            time: "3:00 PM - 05:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/13dcd9816005c2b54049cd9ae872fe0d",
                        },
                        {
                            name: "Basildon",
                            time: "3:00 PM - 05:00",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/49f071b8cc53cfefbaf30b292f07ac4b",
                        },
                        {
                            name: "Amersham",
                            time: "3:00 PM - 05:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/7c829336c65a9950ee1b8debebf3d969",
                        },
                        {
                            name: "Online",
                            time: "9:00 AM - 6:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/e276b44a5befb9ca2eda836a2e0989c8",
                        },
                    ],
                },
                {
                    date: "Sun 04",
                    name: "MR01",
                    description: "1st Math's Revision",
                    testtype: "",
                    centers: [
                        {
                            name: "ONLINE",
                            time: "09:00 PM - 06:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/f449fec9679f6909ad182b9d48bf9013",
                        },
                    ],
                },
                {
                    date: "Sat 10",
                    name: "Topic Test",
                    description: "M05 Math's Money",
                    testtype: "ECOMP04 (CSSE) - 4th English Comprehension",
                    centers: [
                        {
                            name: "Chelmsford",
                            time: "01:00 PM - 03:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/03fe1c7cd1f888090e17988ae98059fe",
                        },
                    ]
                },
                {
                    date: "Sat 10",
                    name: "Topic Test",
                    description: "M05 Math's Money",
                    testtype: "ECOMP04 (CSSE) - 4th English Comprehension",
                    centers: [
                        {
                            name: "Ilford",
                            time: "05:30 PM - 07:30 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/03fe1c7cd1f888090e17988ae98059fe",
                        },
                        {
                            name: "Basildon",
                            time: "04:00 PM - 06:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/8cc942dbb815a25f7cfc5eb97469c9a3",
                        },
                        {
                            name: "Southend-on-Sea",
                            time: "03:00 PM - 05:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/b13bff98f538a76a446d2e305a3d29ac",
                        },
                        {
                            name: "Online",
                            time: "9:00 AM - 6:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/6b30a55c0a757c103de800d908abc32e",
                        },
                    ],
                },
                {
                    date: "Sun 18",
                    name: "M06",
                    description: "Math's Data Handling Part 1",
                    testtype: "VRNVR02 - 2nd Verbal Non-Verbal Reasoning & CW02",
                    centers: [
                        {
                            name: "Illford",
                            time: "05:30 AM - 7:30 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/4fb4c835a7d8a3542ae5bce115a9fa99",
                        },
                        {
                            name: "Amersham",
                            time: "03:00 AM - 05:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/4fb4c835a7d8a3542ae5bce115a9fa99",
                        },
                        {
                            name: "Bexleyheath",
                            time: "12:00 AM - 02:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/4fb4c835a7d8a3542ae5bce115a9fa99",
                        },
                        {
                            name: "Online",
                            time: "9:00 AM - 6:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/4fb4c835a7d8a3542ae5bce115a9fa99",
                        },
                    ],
                },
                {
                    date: "Sat 24",
                    name: "Topic Test",
                    description: "M07 Maths Day, Date and Time",
                    testtype: "ECOMP05 (GL) - 5th English Comprehension",
                    centers: [
                        {
                            name: "Chelmsford",
                            time: "01:00 PM - 02:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/4fb4c835a7d8a3542ae5bce115a9fa99",
                        },
                    ]
                },
                {
                    date: "Sun 25",
                    name: "Topic Test",
                    description: "M07 Maths Day, Date and Time",
                    testtype: "ECOMP05 (GL) - 5th English Comprehension",
                    centers: [
                        {
                            name: "Illford",
                            time: "05:30 PM - 7:30 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/4fb4c835a7d8a3542ae5bce115a9fa99",
                        },
                        {
                            name: "Amersham",
                            time: "03:00 PM - 05:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/4fb4c835a7d8a3542ae5bce115a9fa99",
                        },
                        {
                            name: "Basildon",
                            time: "04:00 PM - 06:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/4fb4c835a7d8a3542ae5bce115a9fa99",
                        },
                        {
                            name: "Online",
                            time: "9:00 AM - 6:00 PM",
                            url: "https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/4fb4c835a7d8a3542ae5bce115a9fa99",
                        },
                    ],
                },
            ],
        };
    }
    TimetableService.prototype.getMonths = function () {
        return ["April", "May",];
    };
    TimetableService.prototype.getTimetable = function () {
        return this.timetableData;
    };
    TimetableService.ngInjectableDef = i0.defineInjectable({ factory: function TimetableService_Factory() { return new TimetableService(); }, token: TimetableService, providedIn: "root" });
    return TimetableService;
}());
export { TimetableService };
