import { Injectable } from '@angular/core';
import { MonthTimetable, TestEvent } from '../models/event.model';

@Injectable({ providedIn: 'root' })
export class TimetableService {
  private timetableData: MonthTimetable = {

'March': [

                  {
                    date: 'Sat 15',
                    name: 'CSSE01',
                    description: 'Maths, Eng Reading Comp. & Creative Writing',
                    testtype: "1st CSSE Style 11+ Mock Test",
                    centers: [
                      
                        { name: 'Chelmsford', time: '01:00 PM - 3:30 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/273884eebbc23ffd9f5dd171ad871b5b' },
                        
                    ]
                },

                {
                    date: 'Sun 16',
                    name: 'CSSE01',
                    description: 'Maths, Eng Reading Comp. & Creative Writing',
                    testtype: "1st CSSE Style 11+ Mock Test",
                    centers: [
                      
                        { name: 'Ilford', time: '05:30 PM - 08:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/72c98fe39eba6098bece58ccb591fab8' },
                        { name: 'Basildon', time: '04:00 PM - 06:30 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/74c2e5c5f8175d548d52d96114549398' },
                        { name: 'Southend-On-Sea', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                        { name: 'Online', time: '09:00 AM - 06:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/3210adcfc8bad1f1e08b2b66ddd57589' }
                    ]
                },

                {
                  date: 'Sun 23',
                  name: 'GLBU01',
                  testtype: "BUCKS, GL Assessment 01",
                  description: 'Maths, Eng, Verbal Reasoning(VR), Non Verbal Reasoning(NVR)',
                  centers: [
                      { name: 'Amershem', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/91b1b6864a06a4843d3d4c182de29826' },
                      { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/c32acfd4673374894bc7702d24d17f19' }
                  ]
              },

              {
                date: 'Sun 23',
                name: 'GLBR01',
                testtype: "1st GL Asessment, Redbridge",
                description: 'Maths, Eng, Verbal Reasoning(VR), Non Verbal Reasoning(NVR)',
                centers: [
                    { name: 'Ilford', time: '05:30 PM - 08:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/141a4008864648f0de9fc173908f1a28' },
                    { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/d681e62123ab44dc1d1b3bc1171ed145' }
                ]
            },
            {
              date: 'Sun 23',
              name: 'GLKE01',
              description: 'Maths, English, VR, NVR',
              testtype: "1st GL Asessment, Kent",
              centers: [
                  { name: 'Bexleyheath', time: '12:00 PM - 02:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/beafc016ee21613b1572e85115b0bc9d' },
                  { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/9ad59933748205a1866885ad6a2ba7a4' }
              ]
          },

          {
            date: 'Sat 29',
            name: 'FSCE01',
            description: 'Maths, Eng, Creative Writing',
            testtype: "1st FSCE Style 11+ Mock Test",
            centers: [

                { name: 'CHELMSFORD', time: '01:00 PM - 3:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/0271cb99c6c8dbf16b0d1e6006c1078d' },


            ]
        },
          {
            date: 'Sun 30',
            name: 'FSCE01',
            description: 'Maths, Eng, Creative Writing',
            testtype: "1st FSCE Style 11+ Mock Test",
            centers: [
                { name: 'Ilford', time: '05:30 PM - 07:30 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/ad766ab77da4266355c894c5cfb0d8ae' },
                { name: 'Basildon', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/05128621f6f46d86ac331042ff0c8310' },
                { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/8316c033717ac65d7af08d30e45fc32c' }
            ]
        },
        {
          date: 'Sun 30',
          name: 'GLQE01',
          description: 'Maths, Eng, Verbal Reasoning(VR)',
          testtype: "1st GL Asessment, QE Barnet",
          centers: [
              { name: 'Ilford', time: '05:30 PM - 07:30 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/f126e5e2a53b4c709f067216a4b20c35' },
              { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/0004a003f9de95f93e8c7f80e83faf5f' }
            ]
          },
        {
          date: 'Sun 30',
          name: 'GLHBS01',
          description: 'Maths, Eng, VR, NVR',
          testtype: "1st GL Asessment, HBS",
          centers: [
              { name: 'Ilford', time: '05:30 PM - 07:30 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/6b7df4d958cf89ece352db065ec066b3' },
              { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/352f4cc199a562c56aabac3a5ed95e4b' }
          ]
      }
            ],
'April': [



                {
                  date: 'Sat 05',
                  name: 'CSSE02',
                  description: 'Maths, Eng, Comp., VR, Creative Writing',
                  testtype: "2nd CSSE Style 11+ Mock Test",
                  centers: [
                      { name: 'Chelmsford', time: '01:00 PM - 3:30 PM' ,url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/3f3c531f94811931d40af2e7cb1bef30'},
                  ]
              },

              {
                    date: 'Sun 06',
                    name: 'CSSE02',
                    description: 'Maths, Eng, Comp., VR, Creative Writing',
                    testtype: "2nd CSSE Style 11+ Mock Test",
                    centers: [
                        { name: 'Ilford', time: '05:30 PM - 08:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/a1c92f367d898d64214237c2218d9099' },
                        { name: 'Basildon', time: '04:00 PM - 06:30 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/8b5cd988b16cf9d2d698e13d0f966855' },
                        { name: 'Southend-On-Sea', time: '09:30 PM - 12:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/8fe3a70fe47414e6ecb41b139360fca5' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/2f441ba76acd2cb42263ded7968721c4' }
                    ]
                },

                {
                  date: 'Sun 13',
                  name: 'GLBU02',
                  description: 'Maths, Eng, VR, NVR',
                  testtype: "BUCKS, GL Assessment 02",
                  centers: [
                      { name: 'Amerhsam', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/1b9f7ab99375536d40495e468b7bb4f0' },
                      { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/dd30d960a6a46d7749041ab9b9f7818f' }
                  ]
              },
                {
                  date: 'Sun 13',
                  name: 'GLRB02',
                  description: 'Maths, Eng, VR, NVR',
                  testtype: "2nd GL Asessment, Redbridge",
                  centers: [
                      { name: 'Ilford', time: '05:30 PM - 07:30 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/7414eabb20c475766bfb4cdac41b8cf4' },
                      { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/8ec437821df73578e7cc6f9a44eef8b9' }
                  ]
              },
              {
                date: 'Sun 13',
                name: 'GLKE02',
                description: 'Maths, Eng, VR, NVR',
                testtype: "2nd GL Asessment, Kent",
                centers: [
                    { name: 'Bexleyheath', time: '12:00 PM - 02:30 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/cbde1b92bae4bcab7676928a18152c47' },
                    { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/28042dd037f39e46e52eb34e90b6f942' }
                ]
            },

            {
              date: 'Sat 26',
              name: 'CSSE03',
              description: 'Maths, Eng, VR, NVR',
              testtype: "3rd CSSE Style 11+ Mock Test",
              centers: [
                  { name: 'CHELMSFORD', time: '01:00 PM - 3:30 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/0dd291df3c214434118c5f354c5d663d' },
              ]
          },
            {
              date: 'Sun 27',
              name: 'CSSE03',
              description: 'Maths, Eng, VR, NVR',
              testtype: "3rd CSSE Style 11+ Mock Test",
              centers: [
                  { name: 'ILFORD', time: '05:30 PM - 08:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/4be6b14adeaf5768c8e0e94c8e8d70bf' },
                  { name: 'BASILDON', time: '04:00 PM - 06:30 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/5c654e134d73d2618f8f7cd5be1754da' },
                  { name: 'Southend-On-Sea', time: '09:30 PM - 12:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/bb4f1fb62340fa7c2b57a58e209e4516' },
                  { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/9930536a969799c04cd80acd0f654dd5' }
              ]
          }
            ],

            
'May': [

                  {
                    date: 'Sat 03',
                    name: 'FSCE02',
                    description: 'Maths, Eng, Creative Writing',
                    testtype: "2nd FSCE Style 11+ Mock Test",
                    centers: [
                        { name: 'Chelmsford', time: '01:00 PM - 3:30 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/68cb3ebe8191b3458eac90df056b00b3' },
                    ]
                },

                {
                    date: 'Sun 04',
                    name: 'FSCE02',
                    description: 'Maths, Eng, Creative Writing',
                    testtype: "2nd FSCE Style 11+ Mock Test",
                    centers: [
                        { name: 'Ilford', time: '05:30 PM - 07:30 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/633aed1f820f2594692fe6bc455df018' },
                        { name: 'Chelmsford', time: '01:30 PM - 3:30 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/68cb3ebe8191b3458eac90df056b00b3' },
                        { name: 'Basildon', time: '04:00 PM - 06:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/f5a5d829047a2a30f5cbbc1c45625285' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/44a912b16317692998067b5f4ea63a67' }
                    ]
                },
                {
                  date: 'Sun 04',
                  name: 'GLQE02',
                  description: 'Maths, English, VR',
                  testtype: "2nd GL Asessment, QE Barnet",
                  centers: [
                    { name: 'Ilford', time: '05:30 PM - 07:30 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/cc4d509283a3f0caa426e6f56543da6a' },
                    { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/b4a472b18331e36836e0eedce36dc719' }
                  ]
              },
              {
                date: 'Sun 04',
                name: 'GLHBS02',
                description: 'Maths, Eng, VR, NVR',
                testtype: "2nd GL Asessment, HBS",
                centers: [
                    { name: 'Ilford', time: '05:30 PM - 07:30 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/067d09b64a355896ffbd8d29c37e4e91' },
                    { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/fc3d52598d472fc7141e88ff2625ab61' }
                ]
            },

            {
              date: 'Sat 10',
              name: 'CSSE04',
              description: 'Maths, Eng, Comp, VR, Creative Writing',
              testtype: "4th CSSE Style 11+ Mock Test",
              centers: [
                { name: 'CHELMSFORD', time: '01:00 PM - 3:30 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/8e828fc99e6e43f7486c45e219b0c2f3' },
              ]
          },

            {
              date: 'Sun 11',
              name: 'CSSE04',
              description: 'Maths, Eng, Comp, VR, Creative Writing',
              testtype: "4th CSSE Style 11+ Mock Test",
              centers: [
                { name: 'ILFORD', time: '05:30 PM - 08:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/bf4d71b1e4aeb06c1a70a5eabc005b77' },
                { name: 'CHELMSFORD', time: '01:30 PM - 04:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/8e828fc99e6e43f7486c45e219b0c2f3' },
                { name: 'BASILDON', time: '04:00 PM - 06:30 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/7757c20bfaedde7c224fb11e3f97d6af' },
                { name: 'Southend-On-Sea', time: '09:30 PM - 12:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/861404f216249e6e9a3c1abb08961db5' },
                { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/a92c0dca871798ebdb821dd1f80f95a5' }
              ]
          },

          {
            date: 'Sun 18',
            name: 'GLBU03',
            description: 'Maths, Eng, VR, NVR',
            testtype: "BUCKS, GL Assessment 03",
            centers: [
              { name: 'Amersham', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/21f1df83956a527b98dcb7642d34a683' },
              { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/dda5fc5f97d967aa8f38c767b76ee6f5' }
            ]
        },

          {
            date: 'Sun 18',
            name: 'GLRB03',
            description: 'Maths, Eng, VR, NVR',
            testtype: "3rd GL Asessment, Redbridge",
            centers: [
              { name: 'ILFORD', time: '05:30 PM - 07:30 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/1971fd68085bc3a3030b73532f62db63' },
              { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/69e9a2e35d56e41d7c7dbba2fb0a025b' }
            ]
        },
        {
          date: 'Sun 18',
          name: 'GLKE03',
          description: 'Maths, Eng, VR, NVR',
          testtype: "3rd GL Asessment, Kent",
          centers: [
              { name: 'Bexleyheath', time: '12:00 PM - 02:30 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/f805ec57f1c9be48fd07cb57ec53389e' },
              { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/1168b2d161bb0b64716208e66ce67025' }
          ]
      },

      {
        date: 'Sat 24',
        name: 'CSSE05',
        description: 'Maths, English, Comp VR, Creative Writing',
        testtype: "5th CSSE Style 11+ Mock Test",
        centers: [
            { name: 'CHELMSFORD', time: '01:00 PM - 3:30 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/52b961e20ecdedfa9c0bd2b295fb485a' },
        ]
      },
      {
        date: 'Sun 25',
        name: 'CSSE05',
        description: 'Maths, English, Comp VR, Creative Writing',
        testtype: "5th CSSE Style 11+ Mock Test",
        centers: [
            { name: 'ILFORD', time: '05:30 PM - 08:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/01d3f3ee24c29fafe44819f9c3dedcf0' },
            { name: 'BASILDON', time: '04:00 PM - 06:30 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/adefabf3a7bb989a96914496198baffe' },
            { name: 'Southend-On-Sea', time: '9:30 AM - 12:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/58bb5397ea2152a8c6d18c03e189874b'},
            { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test/90f6668f132d31f80e5b58674dd73722' }
        ]
      }
            ],
      'June': [
                {
                    date: 'Sun 01',
                    name: 'FSCE03',
                    description: 'Maths, Eng, Creative Writing',
                    testtype: "3rd FSCE Style 11+ Mock Test",
                    centers: [
                        { name: 'Ilford', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                        { name: 'Chelmsford', time: '2:00 PM - 4:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                        { name: 'Basildon', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                        { name: 'Amershem', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
                    ]
                },
                {
                  date: 'Sun 01',
                  name: 'GLQE03',
                  description: 'Maths, English, VR',
                  testtype: "3rd GL Asessment, QE Barnet",
                  centers: [
                    { name: 'Ilford', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                    { name: 'Harrow', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                    { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
                  ]
              },
              {
                date: 'Sun 01',
                name: 'GLHBS03',
                description: 'Maths, Eng, VR, NVR',
                testtype: "3rd GL Asessment, HBS",
                centers: [
                  { name: 'Ilford', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                  { name: 'Harrow', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                  { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
                ]
            },
            {
              date: 'Sun 08',
              name: 'CSSE06',
              description: 'Maths, Eng, Comp, VR, Creative Writing',
              testtype: "6th CSSE Style 11+ Mock Test",
              centers: [
                { name: 'ILFORD', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                { name: 'CHELMSFORD', time: '2:00 PM - 4:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                { name: 'BASILDON', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                { name: 'Southend-On-Sea', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
              ]
          },
          {
            date: 'Sun 15',
            name: 'GLRB04',
            description: 'Maths, Eng, VR, NVR',
            testtype: "4th GL Asessment, Redbridge",
            centers: [
              { name: 'Ilford', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
              { name: 'Amersham', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
              { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
            ]
        },
        {
          date: 'Sun 15',
          name: 'GLKE04',
          description: 'Maths, Eng, VR, NVR',
          testtype: "4th GL Asessment, Kent",
          centers: [
              { name: 'Bexleyheath', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
              { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
          ]
      },
      {
        date: 'Sun 22',
        name: 'CSSE07',
        description: 'Maths, English, Comp VR, Creative Writing',
        testtype: "7th CSSE Style 11+ Mock Test",
        centers: [
          { name: 'ILFORD', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'CHELMSFORD', time: '2:00 PM - 4:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'BASILDON', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'Southend-On-Sea', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
        ]
      },
      {
        date: 'Sun 29',
        name: 'FSCE04',
        description: 'Maths, English, Creative Writing',
        testtype: "4th FSCE Style 11+ Mock Test",
        centers: [
          { name: 'ILFORD', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'CHELMSFORD', time: '2:00 PM - 4:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'BASILDON', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'Amersham', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
        ]
      },
      {
        date: 'Sun 29',
        name: 'GLQE04',
        description: 'Maths, English, VR',
        testtype: "4th GL Asessment, QE Barnet",
        centers: [
            { name: 'ILFORD', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
            { name: 'Harrow', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
            { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
        ]
      }
      ,
      {
        date: 'Sun 29',
        name: 'GLHBS04',
        description: 'Maths, English, VR, NVR',
        testtype: "4th GL Asessment, HBS",
        centers: [
          { name: 'ILFORD', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'Harrow', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
        ]
      }
            ],
      'July': [
                {
                    date: 'Sun 06',
                    name: 'CSSE08',
                    description: 'Maths, Eng, Comp, VR, Creative Writing',
                    testtype: "8th CSSE Style 11+ Mock Test",
                    centers: [
                        { name: 'Ilford', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                        { name: 'Chelmsford', time: '2:00 PM - 4:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                        { name: 'Basildon', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                        { name: 'Southend-On-Sea', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
                    ]
                },
                {
                  date: 'Sun 13',
                  name: 'GLRB05',
                  description: 'Maths, English, VR, NVR',
                  testtype: "5th GL Asessment, Redbridge",
                  centers: [
                    { name: 'Ilford', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                    { name: 'Amersham', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                    { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
                  ]
              },
              {
                date: 'Sun 13',
                name: 'GLKE05',
                description: 'Maths, Eng, VR, NVR',
                testtype: "5th GL Asessment, Kent",
                centers: [
                  { name: 'Bexleyheath', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                  { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
                ]
            },
            {
              date: 'Sun 13',
              name: 'SET01',
              description: 'SUTTON 1',
              testtype: "Sutton1",
              centers: [
                { name: 'Sutton', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
              ]
          },
          {
            date: 'Sun 20',
            name: 'CSSE09',
            description: 'Maths, Eng, Comp, VR, Cretive Writing',
            testtype: "9th CSSE Style 11+ Mock Test",
            centers: [
              {name: 'ILFORD', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
              { name: 'CHELMSFORD', time: '2:00 PM - 4:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
              { name: 'BASILDON', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
              { name: 'Southend-On-Sea', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
              { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
            ]
        },
        {
          date: 'Sun 27',
          name: 'GLQE05',
          description: 'Maths, Eng, VR, NVR',
          testtype: "5th GL Asessment, QE Barnet",
          centers: [
            { name: 'ILFORD', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
            { name: 'Harrow', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
            { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
          ]
      },
      {
        date: 'Sun 27',
        name: 'GLHBS05',
        description: 'Maths, English, Comp VR, Creative Writing',
        testtype: "5th GL Asessment, HBS",
        centers: [
          { name: 'ILFORD', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'Harrow', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
        ]
      },
      {
        date: 'Sun 27',
        name: 'FSCEO5',
        description: 'Maths, English, Creative Writing',
        testtype: "5th FSCE Style 11+ Mock Test",
        centers: [
          { name: 'ILFORD', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'CHELMSFORD', time: '2:00 PM - 4:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'BASILDON', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'Amersham', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
        ]
      },
            ],
      'August': [
                {
                    date: 'Sun 03',
                    name: 'CSSE10',
                    description: 'Maths, Eng, Comp, VR, Creative Writing',
                    testtype: "10th CSSE Style 11+ Mock Test",
                    centers: [
                        { name: 'Ilford', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                        { name: 'Chelmsford', time: '2:00 PM - 4:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                        { name: 'Basildon', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                        { name: 'Southend-On-Sea', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
                    ]
                },
                {
                  date: 'Sun 10',
                  name: 'GLRB06',
                  description: 'Maths, English, VR, NVR',
                  testtype: "6th GL Asessment (Essex/Redbridge)",
                  centers: [
                    { name: 'Ilford', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                    { name: 'Amersham', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                    { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
                  ]
              },
              {
                date: 'Sun 10',
                name: 'GLKE06',
                description: 'Maths, Eng, VR, NVR',
                testtype: "6th GL Asessment Kent",
                centers: [
                  { name: 'Bexleyheath', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                  { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
                ]
            },
            {
              date: 'Sun 10',
              name: 'SET02',
              description: 'SUTTON 2',
              testtype: "Sutton 2",
              centers: [
                { name: 'Sutton', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
              ]
          },
          {
            date: 'Sun 17',
            name: 'FSCE06',
            description: 'Maths, Engish, Cretive Writing',
            testtype: "6th FSCE Style 11+ Mock Test",
            centers: [
              {name: 'ILFORD', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
              { name: 'Amersham', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
              { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
            ]
        },
        {
          date: 'Sun 17',
          name: 'GLQE06',
          description: 'Maths, Eng, VR',
          testtype: "6th GL Asessment, QE Barnet",
          centers: [
            { name: 'ILFORD', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
            { name: 'Harrow', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
            { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
          ]
      },
      {
        date: 'Sun 17',
        name: 'GLHBS06',
        description: 'Maths, English, VR, NVR',
        testtype: "6th GL Asessment, HBS",
        centers: [
          { name: 'ILFORD', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'Harrow', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
        ]
      },
      {
        date: 'Sun 24',
        name: 'CSSE11',
        description: 'Maths, English, Comp, VR, Creative Writing',
        testtype: "11th CSSE Style 11+ Mock Test",
        centers: [
          { name: 'ILFORD', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'CHELMSFORD', time: '2:00 PM - 4:00 PM' ,url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test'},
          { name: 'BASILDON', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'Southend-On-Sea', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
        ]
      },
      {
        date: 'Sun 31',
        name: 'GLRB07',
        description: 'Maths, English, VR, NVR',
        testtype: "7th GL Asessment (Essex/Redbridge)",
        centers: [
          { name: 'ILFORD', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'Amersham', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
          { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
        ]
      },
            ],
      'September': [
                {
                    date: 'Sun 07',
                    name: 'CSSE12',
                    description: 'Maths, Eng, Comp, VR, Creative Writing',
                    testtype: "12th CSSE Style 11+ Mock Test",
                    centers: [
                        { name: 'Ilford', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                        { name: 'Chelmsford', time: '2:00 PM - 4:00 PM' ,url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test'},
                        { name: 'Basildon', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                        { name: 'Southend-On-Sea', time: '03:00 PM - 05:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM',url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-mock-test' }
                    ]
                },
          ]
        };

  getMonths(): string[] {
    return ['March', 'April', 'May', 'June', 'July', 'August', 'September'];
  }

  getTimetable(): MonthTimetable {
    return this.timetableData;
  }
} 