import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { CmsserviceService } from '../../common-cms-service/cmsservice.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import Swiper from 'swiper';
import { Router } from '@angular/router';
declare var jquery: any;
declare var $: any;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {
  ngUnsubscribe = new Subject<void>();
  welcome: any;
  upcomingEventsrecord: any;
  images: any;
  banner: any;
  title = 'Sehyog';
  howithelp: any;
  siteSetting: any;
  constructor(
    private cmsService: CmsserviceService,
    private router: Router ,
    private titleService: Title, private meta: Meta) {
      this.meta.addTags([ 
        { name: 'description', content: 'This is 11plus Examination portal' }, 
        { name: 'keywords', content: 'gcse revision, gcse maths, gcse past papers, gcse science, gcse maths past papers, gcse revision websites, science gcse online, english gcse revision, mathematics gcse, science and maths tutor, online maths test, english language gcse revision, edexcel gcse maths, 11 plus exam papers, 11 plus tuition, 11 plus tutor, 11 plus tutors near me, 11 plus mock exams, 11 plus mock test, 11 plus papers, 11 plus test papers, eleven plus tuition, eleven plus mock exams, eleven plus papers, eleven plus tutors, 11 plus exam tuition, eleven plus exam papers, 11 plus exam tutors, 11 plus exam mock test, eleven plus test papers, eleven plus mock test, eleven plus verbal reasoning' } 
    ]);
  }

  slides = [
    { image: 'assets/images/sl-1.png', title: 'Online Tutoring', description: 'Click for more', route: '/11Plus-mocktest' },
    { image: 'assets/images/sl-2.png', title: 'Mock Assesment', description: 'Click for more', route: '11Plus-classes' },
    { image: 'assets/images/sl-3.png', title: 'Crash Courses', description: 'Click for more', route: 'https://example.com/3' },
  ];

  items = [
    { image: 'assets/images/event1.png', title: 'CSSE04 - 4th SET of CSSE Style 11+', location: 'Southend-on-Sea + 2 more', date: '11/05/2025' },
    { image: 'assets/images/event1.png', title: 'CSSE02 - 2nd SET of CSSE Style 11+', location: 'Southend-on-Sea + 2 more', date: '06/04/2025' },
    { image: 'assets/images/event1.png', title: 'CSSE03 - 3rd SET of CSSE Style 11+', location: 'Southend-on-Sea + 2 more', date: '27/04/2025' },

    { image: 'assets/images/event1.png', title: 'CSSE03 - 3rd SET of CSSE Style 11+', location: 'Southend-on-Sea + 2 more', date: '27/04/2025' },
  ];

  

  currentSlide = 0;
  slideInterval: any;

  ngOnInit() {
    this.startAutoSlide();

    this.cmsService.commonSiteSettingSingleton().subscribe(res => {
      this.siteSetting = res;
    });
    $('.loader-bg').fadeIn();
    this.titleService.setTitle(this.title);  
    this.upcomingTest();
  }

  /** comment
   * upcoming Session listing home page
   */
  upcomingTest() {
    this.cmsService.upcomingEventsRecord()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.images = res.data;
      });
  }

  

  startAutoSlide() {
    this.slideInterval = setInterval(() => {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    }, 5000); // Change slide every 3 seconds
  }

  onSlideClick(index: number) {
    this.router.navigate([this.slides[index].route]); // Navigate to route instead of opening external link
  }


  //Gallery Section 
  gal_images = [
    { src: 'assets/images/ps1.jpg', title: 'Online Class Session' },
    { src: 'assets/images/gal_img1.jpg', title: 'Award Ceremony' },
    { src: 'assets/images/gal_img2.jpg', title: 'Exam Preparation' },
    { src: 'assets/images/gal_img3.jpg', title: 'Cultural Event' },
  ];

  currentIndex = 0;
  isSliding = false;

  get currentImage() {
    return this.gal_images[this.currentIndex].src;
  }

  get currentTitle() {
    return this.gal_images[this.currentIndex].title;
  }

  changeImage(index: number) {
    if (index !== this.currentIndex) {
      this.isSliding = true; // Start animation
      setTimeout(() => {
        this.currentIndex = index;
        this.isSliding = false; // Reset animation
      }, 300);
    }
  }


  ngOnDestroy() {
    if (this.slideInterval) {
      clearInterval(this.slideInterval);
    }
  }

  openWhatsApp() {
    window.open('https://wa.me/447464772331', '_blank');
  }


  // testimonials starts here 



 
}
