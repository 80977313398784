import { MonthTimetable } from '../models/event.model';
import * as i0 from "@angular/core";
var TimetableService = /** @class */ (function () {
    function TimetableService() {
        this.timetableData = {
            'February': [
                {
                    date: 'Sun 09',
                    name: 'M12 + VRNVR04',
                    description: 'Data Handling Part 2',
                    testtype: "4th Verbal Non Verbal Reasoning",
                    centers: [
                        { name: 'Ilford', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/33043662da0f350a17ee0bc701b5c32c' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/c856c315e7aa1de1921c8a3a565a8f21' },
                        { name: 'Basildon', time: '9:00 AM - 6:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/c7965bfc9c6594af9c4a4f65472ba3cc' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/gcse-mock-test' }
                    ]
                },
                {
                    date: 'Sun 16',
                    name: 'MR04',
                    description: '4th Maths Revision ',
                    testtype: "",
                    centers: [
                        { name: 'Online', time: '9:00 AM - 6:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/21bcf6b8eb7ad0b84b91d378a7fe2f97' }
                    ]
                },
                {
                    date: 'Sat 22',
                    name: 'M13 + ECOMP09',
                    testtype: "Shapes Area Perimeter 2",
                    description: '9th English Comprehension',
                    centers: [
                        { name: 'Chelmsford', time: '02:00 PM - 4:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/84e62e6298e2fb0c422ad2140f9c3ae6' },
                    ]
                },
                {
                    date: 'Sun 23',
                    name: 'M13 + ECOMP09',
                    testtype: "Shapes Area Perimeter 2",
                    description: '9th English Comprehension',
                    centers: [
                        { name: 'Ilford', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/2de4707becae04833fd89f5ce4828b05' },
                        { name: 'Balisdon', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/42293d7b9176e0d3987a74cb51439a76' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/9d9e9452c3602c63a94aa00beccbd032' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/71602ed081aa9c9fb28886dc9a10b6c8' }
                    ]
                },
            ],
            'March': [
                {
                    date: 'Sat 01',
                    name: 'M14 + ECOMP10',
                    description: 'Maths Algebra Part 2',
                    testtype: "10th English Reading Comprehension",
                    centers: [
                        { name: 'Chelmsford', time: '02:00 PM - 4:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/0ef9460c460645560f5bbbc1b3257ad7' },
                    ]
                },
                {
                    date: 'Sun 02',
                    name: 'M14 + ECOMP10',
                    description: 'Maths Algebra Part 2',
                    testtype: "10th English Reading Comprehension",
                    centers: [
                        { name: 'Ilford', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/13dcd9816005c2b54049cd9ae872fe0d' },
                        { name: 'Basildon', time: '3:00 PM - 05:00', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/49f071b8cc53cfefbaf30b292f07ac4b' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/7c829336c65a9950ee1b8debebf3d969' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/e276b44a5befb9ca2eda836a2e0989c8' }
                    ]
                },
                {
                    date: 'Sat 08',
                    name: 'M15 + CW05',
                    description: 'Maths Working Speed Test (All Topics)',
                    testtype: "5th English Reading Comprehension",
                    centers: [
                        { name: 'Chelmsford', time: '02:00 PM - 4:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/f449fec9679f6909ad182b9d48bf9013' },
                    ]
                },
                {
                    date: 'Sun 09',
                    name: 'M15 + CW05',
                    description: 'Maths Working Speed Test (All Topics)',
                    testtype: "5th Verbal Non Verbal Reasoning",
                    centers: [
                        { name: 'Ilford', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/03fe1c7cd1f888090e17988ae98059fe' },
                        { name: 'Basildon', time: '02:00 PM - 4:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/8cc942dbb815a25f7cfc5eb97469c9a3' },
                        { name: 'Amersham', time: '3:00 PM - 05:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/b13bff98f538a76a446d2e305a3d29ac' },
                        { name: 'Online', time: '9:00 AM - 6:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/6b30a55c0a757c103de800d908abc32e' }
                    ]
                },
                {
                    date: 'Sun 16',
                    name: 'MR05',
                    description: '5th Maths Revision',
                    testtype: "Algebra, Probability, Symmetry, Coordinates, Rotation & Reflection, Mean Mode Median Range",
                    centers: [
                        { name: 'Online', time: '9:00 AM - 6:00 PM', url: 'https://www.sehyog.co.uk/11plus-help-club/category/11-topic-test/4fb4c835a7d8a3542ae5bce115a9fa99' }
                    ]
                },
            ],
        };
    }
    TimetableService.prototype.getMonths = function () {
        return ['February', 'March',];
    };
    TimetableService.prototype.getTimetable = function () {
        return this.timetableData;
    };
    TimetableService.ngInjectableDef = i0.defineInjectable({ factory: function TimetableService_Factory() { return new TimetableService(); }, token: TimetableService, providedIn: "root" });
    return TimetableService;
}());
export { TimetableService };
