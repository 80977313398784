/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./yearfour.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../front-layout/common/header/header.component.ngfactory";
import * as i4 from "../../front-layout/common/header/header.component";
import * as i5 from "../../common-cms-service/cmsservice.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/router";
import * as i8 from "../../common-myaccount-service/common-account-verify.service";
import * as i9 from "ngx-cookie-service";
import * as i10 from "./yearfour.component";
import * as i11 from "../services/year-four.data.service";
var styles_YearfourComponent = [i0.styles];
var RenderType_YearfourComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_YearfourComponent, data: {} });
export { RenderType_YearfourComponent as RenderType_YearfourComponent };
function View_YearfourComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "tab"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectMonth(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { active: 0 }), (_l()(), i1.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "tab"; var currVal_1 = _ck(_v, 2, 0, (_co.selectedMonth === _v.context.$implicit)); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit; _ck(_v, 3, 0, currVal_2); }); }
function View_YearfourComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "center"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "online": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [["class", "book-btn"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Book"]))], function (_ck, _v) { var currVal_0 = "center"; var currVal_1 = _ck(_v, 2, 0, (_v.context.$implicit.name === "Online")); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_2); var currVal_3 = _v.context.$implicit.time; _ck(_v, 6, 0, currVal_3); var currVal_4 = _v.context.$implicit.url; _ck(_v, 7, 0, currVal_4); }); }
function View_YearfourComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "test-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "test-info enhanced"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "test-date"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h3", [["class", "testName"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [["class", "testTopic"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "p", [["class", "testTopic"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "test-centers"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_YearfourComponent_3)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_4 = _v.context.$implicit.centers; _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.date; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.$implicit.testtype; _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.context.$implicit.description; _ck(_v, 9, 0, currVal_3); }); }
export function View_YearfourComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "top"], ["style", "background: url(assets/images/contact-banner.jpg);"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-header", [], null, null, null, i3.View_HeaderComponent_0, i3.RenderType_HeaderComponent)), i1.ɵdid(3, 1294336, null, 0, i4.HeaderComponent, [i5.CmsserviceService, i6.Title, i7.Router, i7.ActivatedRoute, i8.CommonAccountVerifyService, i9.CookieService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "header2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "exam2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h2", [["style", "color: red; font-size: 24px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Exam Hall / Face-2-Face & Online"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h1", [["class", "heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Year 4 Topic Tests 2025"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h2", [["class", "exam2"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" CSSE | FSCE | GL | SET "])), (_l()(), i1.ɵeld(12, 0, null, null, 16, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 8, "div", [["class", "test-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "a", [["class", "btn-tab active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 671744, null, 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(16, 1), (_l()(), i1.ɵted(-1, null, ["Year 4 Topic Tests"])), (_l()(), i1.ɵeld(18, 0, null, null, 3, "a", [["class", "btn-tab"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 671744, null, 0, i7.RouterLinkWithHref, [i7.Router, i7.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(20, 1), (_l()(), i1.ɵted(-1, null, ["Mock Test"])), (_l()(), i1.ɵeld(22, 0, null, null, 2, "div", [["class", "tabs"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_YearfourComponent_1)), i1.ɵdid(24, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(25, 0, null, null, 3, "div", [["class", "timetable-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 2, "div", [["class", "timetable"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_YearfourComponent_2)), i1.ɵdid(28, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 3, 0); var currVal_2 = _ck(_v, 16, 0, "/topic-test"); _ck(_v, 15, 0, currVal_2); var currVal_5 = _ck(_v, 20, 0, "/11Plus-mocktest"); _ck(_v, 19, 0, currVal_5); var currVal_6 = _co.months; _ck(_v, 24, 0, currVal_6); var currVal_7 = _co.timetable[_co.selectedMonth]; _ck(_v, 28, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 15).target; var currVal_1 = i1.ɵnov(_v, 15).href; _ck(_v, 14, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 19).target; var currVal_4 = i1.ɵnov(_v, 19).href; _ck(_v, 18, 0, currVal_3, currVal_4); }); }
export function View_YearfourComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-yearfour", [], null, null, null, View_YearfourComponent_0, RenderType_YearfourComponent)), i1.ɵdid(1, 114688, null, 0, i10.YearfourComponent, [i11.TimetableService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var YearfourComponentNgFactory = i1.ɵccf("app-yearfour", i10.YearfourComponent, View_YearfourComponent_Host_0, {}, {}, []);
export { YearfourComponentNgFactory as YearfourComponentNgFactory };
