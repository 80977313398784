export const environment = {
  production: true,
  baseURL: 'https://www.sehyog.co.uk/services/',
  frontBaseURL: 'https://www.sehyog.co.uk/',
  // baseURL: 'https://staging.sehyog.co.uk/services/',
  // frontBaseURL: 'https://staging.sehyog.co.uk/',
  liveGoogleCaptcha: '6LfP6KcUAAAAAAUlIn5zohaCJBSYir2ZPhoRrXz3',
  vendorDashboardURL: 'https://onlineexam.sehyog.co.uk/CBT2393/UserLogin.aspx?ClientID=239300', // changing as per vendor asked on 10-08-24 https://onlinetestforum.com/ECAS/UserLogin.aspx
};

