import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appCountUp]'
})
export class CountUpDirective implements OnInit {
  @Input('appCountUp') target: number = 0;
  @Input() duration: number = 2000; // Default duration in milliseconds

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.observeElement();
  }

  private observeElement() {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        this.startCounting();
        observer.disconnect(); // Stops observing after animation runs once
      }
    }, { threshold: 0.5 });

    observer.observe(this.el.nativeElement);
  }

  private startCounting() {
    let count = 0;
    const interval = 50; // Delay between increments (lower = faster)
    const steps = this.duration / interval; // Total steps
    const increment = Math.ceil(this.target / steps); // Smaller step for slower effect

    const updateCount = () => {
      count += increment;
      if (count >= this.target) {
        this.el.nativeElement.innerText = this.target;
      } else {
        this.el.nativeElement.innerText = count;
        setTimeout(updateCount, interval); // Slows down the counting
      }
    };

    updateCount();
  }
}
