
<!-- head starts here -->
<div class="tp-hd">

  <app-header ></app-header> 

  <div class="top" >
  

    <p> <strong>SEHYOG Academy</strong><br> Tutoring & Mock Exams </p>
  
  
  <div class="trust-pilot">
  
    <img src="assets/images/trust-pilot.png" alt="ratings">
  
  </div>
    
  </div>

  <!-- <div class="nw-strp">

    <div class="nw-strp-lft">
      <h5>Also Visit:</h5>
        <a class="bk-dm" [routerLink]="['/elev-plus-cls']">11+ Classes</a>
    </div>

    <div class="nw-strp-rgt">
      <h5>Also Visit: </h5>
        <a class="to-nmt" [routerLink]="['/gsce-test']">GCSE Mock Test</a>
    </div>

  
  </div> -->

</div>

<!-- head ends here -->

<!-- <div class="scroll-anim">
	<div class="field">
		<div class="mouse"></div>
	</div>
</div> -->


<!-- 1 animation wave starts here -->

<div class="circle-container top">
  <div class="circle" style="animation-delay: 0s;">
    <img src="assets/images/w1.png" alt="ico-1">
  </div>
  <div class="circle" style="animation-delay: 0.2s;">
    <img src="assets/images/w2.png" alt="ico-2">
  </div>
  <div class="circle" style="animation-delay: 0.4s;">
    <img src="assets/images/w3.png" alt="ico-3">
  </div>
  <div class="circle" style="animation-delay: 0.6s;">
    <img src="assets/images/w4.png" alt="ico-4">
  </div>
  <div class="circle" style="animation-delay: 0.8s;">
    <img src="assets/images/w5.png" alt="ico-5">
  </div>
  <div class="circle" style="animation-delay: 1s;">
    <img src="assets/images/w6.png" alt="ico-6">
  </div>

  <div class="circle" style="animation-delay: 1s;">
    <img src="assets/images/w7.png" alt="ico-6">
  </div>

  <div class="circle" style="animation-delay: 1s;">
    <img src="assets/images/w8.png" alt="ico-6">
  </div>

  <div class="circle" style="animation-delay: 1s;">
    <img src="assets/images/w9.png" alt="ico-6">
  </div>

  <div class="circle" style="animation-delay: 1s;">
    <img src="assets/images/w10.png" alt="ico-6">
  </div>

  <div class="circle" style="animation-delay: 1s;">
    <img src="assets/images/w1.png" alt="ico-6">
  </div>

</div>

<!-- 1 animation wave ends here -->


<div class="content">
  <h2>Our Products & Services</h2>
  <p>Tutoring & Mock Exams | UK & UAE</p>

  <h3 style="text-align: center;">KS2 | 11+ | 13+ | SATs | GCSE | A Levels</h3>
      <p>CSSE | FSCE | GL | SET | ISEB | AQA | OCR | EDEXCEL | EDUQAS</p>
      <p>Study Notes, Lecture/Classes, Mock Exam, Analysis & Feedback</p>
      <button class="btn3">BOOK A FREE DEMO</button>
</div>



<!-- 2 animation wave starts here -->

<div class="circle-container bottom">
  <div class="circle" style="animation-delay: 0s;">
    <img src="assets/images/w10.png" alt="ico-1">
  </div>
  <div class="circle" style="animation-delay: 0.2s;">
    <img src="assets/images/w9.png" alt="ico-2">
  </div>
  <div class="circle" style="animation-delay: 0.4s;">
    <img src="assets/images/w8.png" alt="ico-3">
  </div>
  <div class="circle" style="animation-delay: 0.6s;">
    <img src="assets/images/w7.png" alt="ico-4">
  </div>
  <div class="circle" style="animation-delay: 0.8s;">
    <img src="assets/images/w6.png" alt="ico-5">
  </div>
  <div class="circle" style="animation-delay: 1s;">
    <img src="assets/images/w5.png" alt="ico-6">
  </div>

  <div class="circle" style="animation-delay: 1s;">
    <img src="assets/images/w4.png" alt="ico-6">
  </div>

  <div class="circle" style="animation-delay: 1s;">
    <img src="assets/images/w3.png" alt="ico-6">
  </div>

  <div class="circle" style="animation-delay: 1s;">
    <img src="assets/images/w2.png" alt="ico-6">
  </div>

  <div class="circle" style="animation-delay: 1s;">
    <img src="assets/images/w1.png" alt="ico-6">
  </div>

  <div class="circle" style="animation-delay: 1s;">
    <img src="assets/images/w6.png" alt="ico-6">
  </div>




</div>

<!-- 2 animation wave ends here -->


<!-- Description Starts here -->




<!-- Description Ends here -->

<!-- Numbers info section starts here -->

<div class="num-info">

  <div class="ni-card">
    <div class="sub-nic">
      <h1 appCountUp="10000" [duration]="3000">0</h1>
      <h1>+</h1>
    </div>
    <p>Students</p>
  </div>

  <div class="ni-card">
    <div class="sub-nic">
      <h1 appCountUp="15" [duration]="4000">0</h1>
      <h1>+</h1>
    </div>
    <p>Subjects</p>
  </div>

  <div class="ni-card">
    <div class="sub-nic">
      <h1 appCountUp="10" [duration]="5000">0</h1>
      <h1>+</h1>
    </div>
   
    <p>Focused Boards</p>
  </div>

</div>

<!-- Numbers info section ends here -->


<!-- offering section starts here -->
<div class="offerings-section">

  <h1>Our Offerings</h1>
  <div class="underline"></div>

</div>

<div class="carousel">

  <div class="slides-container">
    
    <!-- Slide 1: Online Tutoring -->
    <div class="slide">
      <div class="slide-container">

        <div class="text-content">
          <h3 class="title">Online Tutoring</h3>
          <h5>We provide personalized, interactive online tutoring for students from Year 3 to Year 13 in various subjects, including:</h5>
          <ul>
            <li>Mathematics</li>
            <li>English Grammar, Creative Writing, Reading Comprehension</li>
            <li>Reasoning - Verbal, Non Verbal, Spatial, Quantitative</li>
            <li>Science (Physics, Chemistry, Biology)</li>
            <li>Computer Science</li>
            <li>Languages (French, Spanish, German, and more)</li>
            <li>History, Geography, and Social Sciences</li>
          </ul>

          <h5>Our tutors are experienced professionals who use innovative teaching methodologies and state-of-the-art tools to make learning engaging and effective.</h5>

          <button onclick="window.open('https://www.sehyog.co.uk/freedemo', '_blank')" style="margin-top: 20px;" class="btn">BOOK A FREE DEMO</button>
        </div>

        <div class="image-content">
          <img src="assets/images/slide-1-img.jpg" alt="Online Tutoring">
        </div>

      </div>
      <div class="info-box-container2">
        <div class="info-box2">
          <div class="ib-head-bx">
            <h4>KS2/11plus</h4>
          </div>
          
          <h5>GL | CSSE | FSCE | SET | ISEB</h5>
          <ul>
            <li>Mathematics</li>
            <li>English Grammar, Creative Writing</li>
            <li>Reasoning - Verbal, Non Verbal, Spatial, Quantitative</li>
          </ul>
        </div>
        <div class="info-box2">
          <div class="ib-head-bx">
            <h4>KS3 & GCSE</h4>
          </div>
          
          <h5>AQA | OCR | EDEXCEL | EDUQAS</h5>
          <ul>
            <li>Mathematics</li>
            <li>Science - Bio, Chem, Physics</li>
            <li>English Literature & Language</li>
            <li>Computer Science</li>
            <li>French, German, Spanish</li>
          </ul>

          

        </div>
        <div class="info-box2">

          <div class="ib-head-bx">
            <h4>AS & A LEVEL</h4>
          </div>

          <h5>AQA | OCR | EDEXCEL | EDUQAS</h5>
          <ul>
            <li>Mathematics</li>
            <li>Science - Bio, Chem, Physics</li>
            <li>English Literature & Language</li>
            <li>Computer Science</li>
            <li>French, German, Spanish</li>
          </ul>
        </div>
      </div>

    </div>

    <div class="div-strp"></div>

    <!-- Slide 2: Mocks and Assessments -->
    <div class="slide">
      <div class="slide-container">

        <div class="text-content">
          <h3 class="title">Mocks and Assessments</h3>
          <h5>We offer structured mock exams and practice assessments to help students:</h5>
          <ul>
            <li>Exposure to Exam Style Questions</li>
            <li>Understanding Exam Conditions & Paper Formats</li>
            <li>Eliminate Exam Fear</li>
            <li>Improve Time Management</li>
            <li>Identify strengths and areas for improvement</li>
            <li>Build exam confidence</li>
          </ul>
          <button onclick="window.open('https://www.sehyog.co.uk/freedemo', '_blank')" class="btn">BOOK A FREE DEMO</button>
        </div>
        <div class="image-content">
          <img src="assets/images/slide-2-img.jpg" alt="Mocks and Assessments">
        </div>

      </div>

      <div class="info-box-container2">
        <div class="info-box2">

          <div class="ib-head-bx">
            <h4>11PLUS MOCK TESTS</h4>
          </div>

          <h5>Face-2-Face & Online</h5>
          <h5>CSSE | FSCE | GL | SET | ISEB</h5>
          <p><b>Centres</b> <br> • ILFORD • CHELMSFORD • SOUTHEND-ON-SEA • BASILDON • BEXLEYHEATH • AMERSHAM • SLOUGH • SUTTON • HARROW</p>
          <a class="mck-cta" [routerLink]="['/mock-test']">Book Mock Test</a>
        </div>
        <div class="info-box2">
          <div class="ib-head-bx">
            <h4>GCSE MOCK TESTS</h4>
          </div>
          <h5>Face-2-Face & Online</h5>
          <h5>AQA | OCR | EDEXCEL | EDUQAS</h5>
          <p><b>Centres</b> <br> • ILFORD • CHELMSFORD • SOUTHEND-ON-SEA • BASILDON • BEXLEYHEATH • AMERSHAM • SLOUGH • SUTTON • HARROW</p>
          <a class="mck-cta" [routerLink]="['/gsce-test']">Book Mock Test</a>
        </div>
      </div>
    </div>

    <div class="div-strp"></div>

    <!-- Slide 3: Crash Courses -->
    <div class="slide">

      <div class="slide-container">
        <div class="text-content">
          <h3 class="title">Crash Courses</h3>
          <h5>Our crash courses are designed to provide intensive, focused learning for students preparing for important exams. Key features include:</h5>
          <ul>
            <li>Short-term, high-impact sessions.</li>
            <li>Exposure to Exam Style Questions</li>
            <li>Comprehensive coverage of exam syllabi.</li>
            <li>Tips and strategies for answering questions effectively.</li>
            <li>Result Analysis Report (RAR)</li>
          </ul>
          <button onclick="window.open('https://www.sehyog.co.uk/freedemo', '_blank')" class="btn">BOOK A FREE DEMO</button>
        </div>
        <div class="image-content">
          <img src="assets/images/slide-3-img.jpg" alt="Crash Courses">
        </div>
      </div>

      <div class="info-box-container2">
        <div class="info-box2">
          <div class="ib-head-bx">
            <h4>11PLUS</h4>
          </div>
          <h5>CSSE | FSCE | GL | SET | ISEB</h5>
          <ul>
            <li>Crash Course April - July</li>
            <li>Creative Writing Course</li>
            <li>Reading Comprehension Course</li>
            <li>HBS Second Round Exam Prep.</li>
            <li>Sutton Second Round Exam Prep.</li>
          </ul>
          <a class="mck-cta" href="https://www.sehyog.co.uk/11plus-help-club/category/11-rapid-course">View Crash Courses</a>
        </div>
        <div class="info-box2">
          <div class="ib-head-bx">
            <h4>GCSE & A LEVELS</h4>
          </div>
          
          <h5>AQA | OCR | EDEXCEL | EDUQAS</h5>
          <ul>
            <li>GCSE Comprehensive Course Sept - April</li>
            <li>GCSE Revision Course March - April</li>
            <li>12+, 13+, SATs Exam Prep.</li>
            <li>Late Entry Exam Prep.</li>
          </ul>
          <a class="mck-cta" href="https://www.sehyog.co.uk/11plus-help-club/category/gcse-rapid-course">View Crash Courses</a>
        </div>
      </div>
    </div>

    <div class="div-strp"></div>

    <!-- Slide 4: Holiday Intensive -->
    <div class="slide">

      <div class="slide-container">
        <div class="text-content">
          <h3 class="title">Summer and Holiday Intensives</h3>
          <h5>Make the most of the holiday season with our intensive programs that focus on :</h5>
          <ul>
            <li>Exposure to Exam Style Questions</li>
            <li>Solving Past Papers</li>
            <li>Mock Exams</li>
            <li>Reinforce foundational concepts.</li>
            <li>Prepare students for the upcoming Exams / academic year.</li>
            <li>Boost confidence and reduce learning gaps.</li>
          </ul>
          <button onclick="window.open('https://www.sehyog.co.uk/freedemo', '_blank')" class="btn">BOOK A FREE DEMO</button>
        </div>
        <div class="image-content">
          <img src="assets/images/1081.jpg" alt="Crash Courses">
        </div>
      </div>
    </div>

  </div>

</div>


<!-- offering section ends here -->


<!-- RAR section starts here -->

<div class="rar-section">

  <h1>Result Analysis Report (RAR)</h1>
  <div class="underline2"></div>

  <div class="rs-sub">
    <div class="sub-left">

      <p class="p-h">A COMPREHENSIVE TOOL</p>
      <p class="p-d">Designed to provide Detailed Insights 
        into your Child's Test Performance.</p>
  
        <p class="p-h">RAR HIGHLIGHTS</p>
        <p class="p-d">Strengths & Areas for Improvement <br>
          Offers targeted feedback to help your child excel</p>
  
          <div style="height: 20px;"></div>
  
          <a class="cta-2" href="https://www.sehyog.co.uk/freedemo">BOOK A FREE DEMO</a>
  
    </div>
  
    
    <div class="sub-right">
      <img  src="assets/images/rar-2.png" alt="">
    </div>
  </div>

  

</div>


<!-- feature section starts here -->

<div class="ft-sc">
  <h1>Why Choose Sehyog Academy?</h1>
  <div class="underline2"></div>

  <div class="ft-sc-sub">

    <div class="ft-card">

      <h3>Qualified Tutors</h3>
      <p>Our team comprises experienced and qualified educators who are passionate about teaching and helping students achieve their goals.</p>

      <img style="margin: 0;" src="assets/images/ft-1.png" alt="">


    </div>

    <div class="ft-card">

      <h3>Flexible Learning</h3>
      <p>We offer flexible scheduling to accommodate busy families and individual student needs.</p>

      <img style="margin-top: 20px;" src="assets/images/ft-2.png" alt="">


    </div>

    <div class="ft-card">

      <h3>Interactive Technology</h3>
      <p>With cutting-edge online platforms, we ensure a seamless and engaging learning experience with interactive tools and resources.</p>

      <img src="assets/images/ft-3.png" alt="">


    </div>

  </div>

  <div style="margin-top: 100px;" class="ft-sc-sub">

    <div class="ft-card">

      <h3>Customized Plans</h3>
      <p>Every student’s journey is unique, and we create tailored learning plans to cater to their specific needs and goals.</p>

      <img style="margin-top: 20px;" src="assets/images/ft-4.png" alt="">

    </div>

    <div class="ft-card">

      <h3>Proven Results</h3>
      <p>Our students consistently achieve excellent academic outcomes, with many excelling in competitive exams and school assessments.</p>

      <img src="assets/images/ft-5.png" alt="">

    </div>



  </div>

  <a style="margin-top: 50px;" class="cta-2" href="https://www.sehyog.co.uk/freedemo">BOOK A FREE DEMO</a>
  

</div>

<!-- featrue section ends here -->


<!-- contact us section starts here -->

<div class="ct-us">

  <h1>Contact Us</h1>

  <div class="sub-ct-us">

    <div class="sb-card">

      <img src="assets/images/hl.png" alt="">

      <div class="txt-ct">

        <h4>Hotline</h4>
        <p>0330 133 9827 </p>
        
  
      </div>

    </div>

    <div class="sb-card" (click)="openWhatsApp()">

      <img src="assets/images/ct-us-wa.png" alt="">

      <div class="txt-ct">

        <h4>WhatsApp</h4>
        <p>+44 7464772331</p>
        
  
      </div>

    </div>


    <div class="sb-card">

      <img src="assets/images/ct-us-m.png" alt="">

      <div class="txt-ct">

        <h4>Email</h4>
        <p>support@sehyog.co.uk </p>
        
  
      </div>

    </div>
   

  </div>

  <div class="circle-container bottom">
    <div class="circle" style="animation-delay: 0s;">
      <img src="assets/images/w10.png" alt="ico-1">
    </div>
    <div class="circle" style="animation-delay: 0.2s;">
      <img src="assets/images/w9.png" alt="ico-2">
    </div>
    <div class="circle" style="animation-delay: 0.4s;">
      <img src="assets/images/w8.png" alt="ico-3">
    </div>
    <div class="circle" style="animation-delay: 0.6s;">
      <img src="assets/images/w7.png" alt="ico-4">
    </div>
    <div class="circle" style="animation-delay: 0.8s;">
      <img src="assets/images/w6.png" alt="ico-5">
    </div>
    <div class="circle" style="animation-delay: 1s;">
      <img src="assets/images/w5.png" alt="ico-6">
    </div>
  
    <div class="circle" style="animation-delay: 1s;">
      <img src="assets/images/w4.png" alt="ico-6">
    </div>
  
    <div class="circle" style="animation-delay: 1s;">
      <img src="assets/images/w3.png" alt="ico-6">
    </div>
  
    <div class="circle" style="animation-delay: 1s;">
      <img src="assets/images/w2.png" alt="ico-6">
    </div>
  
    <div class="circle" style="animation-delay: 1s;">
      <img src="assets/images/w1.png" alt="ico-6">
    </div>
  
    <div class="circle" style="animation-delay: 1s;">
      <img src="assets/images/w6.png" alt="ico-6">
    </div>
  
  
  
  
  </div>

</div>


<!-- contact us section ends here -->

