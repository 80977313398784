<div class="tp-hd">

  <app-header ></app-header> 

  <div class="top" >
  

    <p> <strong>SEHYOG Academy</strong><br> Tutoring & Mock Exams | UK & UAE </p>
  
  
  <div class="trust-pilot">
  
    <img src="assets/images/trust-pilot.png" alt="ratings">
  
  </div>
    
  </div>

  <div class="nw-strp">

    <div class="nw-strp-lft">
      <h5>Also Visit</h5>
        <a class="bk-dm" [routerLink]="['/gcse-classes']">GCSE Classes</a>
    </div>

    <div class="nw-strp-rgt">
      <h5>Also Visit: </h5>
        <a class="to-nmt" [routerLink]="['/mock-test']">11+ Mock Test</a>
    </div>

  
  </div>

</div>

<!-- HERO SECTION STARTS HERE -->

<div class="hero-sec">

  <div class="hero-sub-sec">

    <div class="container-sec">

      <h1>11plus Classes</h1>
  
      <h2>Secure the <span>Better <br> Future </span> for Your Ward</h2>

      <h3>ONLINE 1-2-1 & GROUP CLASSES  </h3>
      <h4>GL | CSSE | FSCE | SET | ISEB</h4>

      <a class="cta-1" href="https://www.sehyog.co.uk/freedemo">BOOK A FREE DEMO</a>
    
    </div>
  
    <img src="assets/images/cir_sm-chd.png" alt="img1">

  </div>
  

</div>

<!-- HERO SECTION ENDS HERE -->


<!-- STRIP 1  -->

 <div class="strp-1">
  <h2>10K+ STUDENTS HAVE BENEFITED SO FAR</h2>
  <a class="cta-2" href="https://www.sehyog.co.uk/freedemo">BOOK A FREE DEMO</a>
 </div>


<!-- STRIP 1 ENDS HERE -->


<!-- section 2 -->

<div class="sec-2">
  <h1>SEHYOG Methodology </h1>


  <img src="assets/images/fourlevel.png" alt="">

  <!-- <h2 class="one-h">4 LEVEL</h2>
  <h2 class="two-h"> REINFORCEMENT</h2>
  <h2 class="three-h"> APPROACH</h2>


  <div class="sec-2-cont">

    <div class="cont-card">
      <h4 style="font-weight: bold;">Lecture/Class</h4>

      <ul>
        <li>High level summary of the Lecture</li>
        <li>Assess student's understanding with practical examples</li>
        <li>Interactive lecture delivery using teaching aids Quick Class</li>
        <li>Assessment (Quiz, Questions, Group Discussions)</li>
        <li>Conclude the lecture with high level summary -Quick</li>
        <li>Summary Video helpful for Lecture revision</li>
      </ul>

    </div>

    <div class="cont-card">
      <h4 style="font-weight: bold;">PLM - Pre Lecture Material</h4>

      <ul>
        <li>Study Notes supplied to the student before subsequent Lecture</li>
        <li>Motivate the student to read the Study Notes</li>
      </ul>

    </div>

  </div>

  <div class="sec-2-cont">

    <div class="cont-card">
      <h4 style="font-weight: bold;">Assessment & Analysis</h4>

      <ul>
        <li>Home Test / Worksheets</li>
        <li>Face to Face (or Online) Unit/Topic Test Face to Face (or Online)</li>
        <li>Full Mock Test in Real Exam like Environment Result Analysis Report</li>
        <li>(RAR) and Historical Trend Analysis  (Rank, Percentile, Standard Score)</li>
        <li>Detailed Marking Scheme & Video Solutions</li>
        <li>Individual Error Log Chronology</li>
      </ul>
    </div>

    <div class="cont-card">
      <h4 style="font-weight: bold;">Feedback & Reinforcement</h4>

      <ul>
        <li>Share student papers/working out with the parents for a triangular review approach.</li>
        <li>Parent Teacher Meeting + Review student papers with Parents & Student</li>
        <li>Highlight weak and strong areas (with help of Individual Error Log)</li>
        <li>(RAR) and Historical Trend Analysis  (Rank, Percentile, Standard Score)</li>
        <li>And Doubt clearing sessions (Reinforcement & Refine Step)</li>
      </ul>

    </div>

  </div> -->
  
  <a class="cta-2" href="https://www.sehyog.co.uk/freedemo">Book for a Free Demo</a>


</div>

<!-- section 2 ends here -->


<!-- RAR Starts here -->


<div class="sec-3">
  <h1>Result Analysis Report (RAR)</h1>

  <div class="sec-3-sub">

    <div class="sub-left">

      <p class="p-h">A COMPREHENSIVE TOOL</p>
      <p class="p-d">Designed to provide Detailed Insights 
        into your Child's Test Performance.</p>

        <p class="p-h">RAR HIGHLIGHTS</p>
        <p class="p-d">Strengths & Areas for Improvement <br>
          Offers targeted feedback to help your child excel</p>

          <div style="height: 20px;"></div>
  
          <a class="cta-2" href="https://www.sehyog.co.uk/freedemo">Book for a Free Demo</a>


    </div>

    
    <div class="sub-right">
      <img  src="assets/images/rar.png" alt="">
    </div>


  </div>


</div>

<!-- RAR ends here -->


<!-- Section 4 Starts here -->


<div class="sec-4">

  <h1>How SEHYOG Enhance Learning?</h1>

  <div class="sec-4-hub">

    <div class="sh-4-left">


      <div class="sh-4-card">

        <div class="cir">

          <img src="assets/images/digital-designing.png" alt="">

        </div>

        <div class="crd-cont">
          <p class="crd-h-4">Interactive Demonstration
          </p>

          <p>Use interactive demonstrations, multimedia resources, or real-life examples to introduce new concepts or illustrate complex ideas. This captures students' attention and makes the content more relatable and memorable.</p>
          
        </div>

      </div>


      <div class="sh-4-card">

        <div class="cir" style="background-color: #DABFFF;">

          <img src="assets/images/collaboration.png" alt="">

        </div>

        <div class="crd-cont">
          <p class="crd-h-4">Collaborative Learning
          </p>

          <p>Incorporate group work and collaborative activities where students can work together to solve problems, discuss ideas, and share their knowledge. This promotes teamwork, communication, and
            peer learning.</p>
          
        </div>

      </div>


      <div class="sh-4-card">

        <div class="cir" style="background-color: #FCE7B7;">

          <img src="assets/images/student.png" alt="">

        </div>

        <div class="crd-cont">
          <p class="crd-h-4">Inquiry Based Learning
          </p>

          <p>Foster curiosity and critical thinking skills by incorporating inquiry-based learning approaches.Encourage students to ask questions, investigate problems, and discover solutions through research, experiments, or project-based tasks.</p>
          
        </div>

      </div>


      <div class="sh-4-card">

        <div class="cir" style="background-color: #FDC1BB;">

          <img src="assets/images/differentiation.png" alt="">

        </div>

        <div class="crd-cont">
          <p class="crd-h-4">Differentiated Learning
          </p>

          <p>Use interactive demonstrations, multimedia resources, or real-life examples to introduce new concepts or illustrate complex ideas. This captures students' attention and makes the content more relatable and memorable.</p>
          
        </div>

      </div>


      <div class="sh-4-card">

        <div class="cir" style="background-color: #FAC5B1;">

          <img src="assets/images/ladder.png" alt="">

        </div>

        <div class="crd-cont">
          <p class="crd-h-4">Scaffold Learning
          </p>

          <p>Adapt your teaching to accommodate the diverse needs and abilities of students. Differentiate instruction by providing additional resources, offering extension activities for high achievers, and providing extra support or alternative explanations for struggling students</p>
          
        </div>

      </div>

    </div>

    <div class="sh-4-right">

      <img src="assets/images/pencil1.png" alt="">

    </div>




  </div>

</div>

<!-- section 4 ends here -->


<!-- strip 2 starts here -->

<div class="strp-2">

<h2>Helping 11+ Students Since 2016</h2>

<a class="cta-2" href="https://www.sehyog.co.uk/freedemo">Book for a Free Demo</a>


</div>

<!-- strip 2 ends here -->


<!-- section 5 starts here -->


<div class="sec-5">

  <h1>SEHYOG Strategy </h1>
  <h2>Comprehensive Offerings Under One Umbrella</h2>

  <div class="sec-5-sub">

    <div class="s5-card">

      <div class="s5-cir">
        <h2>1</h2>
        
      </div>

      <p class="s5-ph">Targeted Study Notes</p>

      <ul>
        <li>Well designed & targeted notes.</li>
        <li>Effective learning resources</li>
      </ul>


    </div>

    <div class="s5-card">

      <div class="s5-cir">
        <h2>2</h2>
        
      </div>

      <p class="s5-ph">Interactive Lectures</p>

      <ul>
        <li>Board wise experienced</li>
        <li>teachers Activate prior learning</li>
        <li>High Level to depth of Concepts</li>
        <li>Quick Lecture Summary Video</li>
      </ul>


    </div>


    <div class="s5-card">

      <div class="s5-cir">
        <h2>3</h2>
        
      </div>

      <p class="s5-ph">All Round Learning</p>

      <ul>
        <li>Technological Aids</li>
        <li>Individualized Support</li>
        <li>Video of class recording for reference</li>
      </ul>


    </div>

    <div class="s5-card">

      <div class="s5-cir">
        <h2>4</h2>
        
      </div>

      <p class="s5-ph">Consistent Assessment</p>

      <ul>
        <li>Class Tests</li>
        <li>Home Test</li>
        <li>Unit/Topic Test</li>
        <li>Full Mock Tes</li>
      </ul>


    </div>


  </div>



  <div class="sec-5-sub">

    <div class="s5-card">

      <div class="s5-cir">
        <h2>5</h2>
        
      </div>

      <p class="s5-ph">Error Log & Feedback</p>

      <ul>
        <li>Parents Teacher Meeting (PTM)</li>
        <li>Self Assessment & Reflection</li>
        <li>Share student progress.</li>
        <li>Highlight Weak & Strong Areas</li>
      </ul>


    </div>

    <div class="s5-card">

      <div class="s5-cir">
        <h2>6</h2>
        
      </div>

      <p class="s5-ph">Past Papers And Full Mocks Tests</p>

      <ul>
        <li>Result Analysis Report (RAR) to Error Log to Feedback to PTM</li>
        <li>Free online Practice Test & Video Solution</li>
      </ul>


    </div>


    <div class="s5-card">

      <div class="s5-cir">
        <h2>7</h2>
        
      </div>

      <p class="s5-ph">Analysis, Feedback, Counselling
        & Improve</p>

      <ul>
        <li>Result Analysis Report (RAR)</li>
        <li>Reinforcement of weak areas</li>
        <li>Review / Doubt Clearing Sessions</li>
      </ul>


    </div>


  </div>

</div>


<!-- section 5 ends here -->


<!-- section 6 starts here -->

<div class="sec-6">

  <h1>Subject & Courses offered </h1>

  <div class="sub-sec6">

    <div class="ss6-card">

      <div class="ss6-cir">

        <img src="assets/images/pi-mathematical-constant-symbol.png" alt="">


      </div>

      <div class="sub-d">

        <p class="sub-name">Mathematics</p>


      </div>

      

    </div>


    <div class="ss6-card">

      <div class="ss6-cir">

        <img src="assets/images/school.png" alt="">


      </div>

      <div class="sub-d">

        <p class="sub-name">English Grammar, Creative Writing, Reading Comprehension</p>
        


      </div>

    </div>

    <div class="ss6-card">

      <div class="ss6-cir">

        <img src="assets/images/sit.png" alt="">


      </div>

      <div class="sub-d">

      </div>

      <p class="sub-name">Verbal Reasoning</p>

    </div>

  </div>

  <div class="sub-sec6">

    <div class="ss6-card">

      <div class="ss6-cir">

        <img src="assets/images/bald-head-with-question-mark.png" alt="">


      </div>

      <p class="sub-name">Non Verbal Reasoning</p>

    </div>

    <div class="ss6-card">

      <div class="ss6-cir">

        <img src="assets/images/technology.png" alt="">


      </div>

      <p class="sub-name">Spatial Reasoning</p>

    </div>

    <div class="ss6-card">

      <div class="ss6-cir">

        <img src="assets/images/quantitative.png" alt="">


      </div>

      <p class="sub-name">Quantitative Reasoning</p>

    </div>

  </div>


  <div class="sub2-s6">

    <div class="ss6-crd">

      <div class="card-sub">

        <img src="assets/images/streaming.png" alt="">

        <h4>FAST FORWARD COURSE WITH 30-40 WEEKS COMMITMENT</h4>

        <p>Fast forward Course for Year 5, Year 4 & Year 3 Students</p>
      </div>

      <div style="height: 50px;"></div>

      <p class="d-h"> RECOMMENDED FOR: <strong>Year 5, 4 & 3 students</strong> </p>

      <p class="d-h"> DURATION:  <strong>30-40 Weeks</strong></p>

      <p class="d-h"> SESSIONS:  <strong>105 - 315 Sessions</strong></p>

      <p class="d-h"> PROVIDED:  </p>

     <p class="d-d">Course Structure & Pre-Lecture Material (PLM), Daily / Weekly Home / Class / Face to Face Unit / Topic Tests, Fortnightly Revision Tests, Weekly Doubt Clearing Sessions</p>


     <p class="d-h">FEE SCHEDULE:</p>

     <p class="d-d">Advance One Time Payment / Monthly Payment</p>


     <a style="align-self: center; margin-top: 25px;" class="cta-2" href="https://www.sehyog.co.uk/freedemo">Book for a Free Demo</a>


    </div>

    <div class="ss6-crd">

      <div class="card-sub">

        <img src="assets/images/online-course.png" alt="">

        <h4>REGULAR COURSE (Pay As You Go) </h4>

        <p>Basics to best level 11+ preparation for Year 3, 
          Year 4 & Year 5 Students</p>
      </div>

      <div style="height: 50px;"></div>

      <p class="d-h"> RECOMMENDED FOR: <strong>Year 5, 4 & 3 students</strong> </p>

      <p class="d-h"> DURATION:  <strong>Flexible</strong></p>

      <p class="d-h"> SESSIONS:  <strong>Flexible</strong></p>

      <p class="d-h"> PROVIDED:  </p>

     <p class="d-d">Course Structure & Pre-Lecture Material (PLM), Daily / Weekly Home / Class / Face to Face Unit / Topic Tests, Revision Tests, Quick Revision & Doubt Clearing Sessions </p>


     <p class="d-h">FEE SCHEDULE:</p>

     <p class="d-d">Monthly Payment</p>



     <a style="align-self: center; margin-top: 25px;" class="cta-2" href="https://www.sehyog.co.uk/freedemo">Book for a Free Demo</a>
    </div>

  </div>

</div>


<!-- section 6 ends here -->


<div class="contact-sec">

  <div class="cs-left">
    <h1>FOR MORE INFO </h1>

    <h2>Contact Team SEHYOG: </h2>

    <h5><strong>Hotline: </strong>0330 133 9827</h5>
    <h5><strong>Email: </strong>support@sehyog.co.uk</h5>
    <h5><strong>WhatsApp: </strong>+44 7464772331</h5>


    <div class="sm-sec">

      <img src="assets/images/linkedin.png" alt="linkedin">
      <img src="assets/images/facebook.png" alt="linkedin">
      <img src="assets/images/youtube.png" alt="linkedin">
      <img src="assets/images/twitter.png" alt="linkedin">
      <img src="assets/images/instagram.png" alt="linkedin">

    </div>
    <h5>@sehyog.academy</h5>

    <a style="margin-top: 25px;" class="cta-2" href="https://www.sehyog.co.uk/freedemo">Book for a Free Demo</a>




  </div>


  <div class="cs-right">
    <img src="assets/images/chid-g.png" alt="">
  </div>


</div>