import { Component, OnInit, OnDestroy, AfterContentInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CmsserviceService } from '../../../common-cms-service/cmsservice.service';
import { Router, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
declare var $: any;
declare const grecaptcha: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy, AfterContentInit {
  pagetitle: string;
  getInTouch: FormGroup;
  pageslug: string;
  categories = [];
  catDetail = [];
  slug: string;
  formData: any;
  siteSetting: any;
  formMessage = '';
  cookieValue = '';
  ngUnsubscribe = new Subject<void>();
  googleCaptcha: boolean;
  googleKey = environment.liveGoogleCaptcha;
  testPopupCondition: boolean;
  constructor(
    private cmsService: CmsserviceService,
    private router: Router,
    private cookiesService: CookieService,
    private fb: FormBuilder
  ) {

    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        // window.scrollTo(0, 0)
        const slugArr = val.url.split('/');
        const allow = ['contact-us', 'donation', 'support', 'request-for-franchise'];
        if (allow.includes(slugArr[slugArr.length - 1])) {
          this.testPopupCondition = false;
        } else {
          this.testPopupCondition = true;
        }
      }
    });
  }
  ngOnInit() {
    this.cmsService.commonSiteSettingSingleton().subscribe(res => {
      this.siteSetting = res;
    });
    this.CategoryList();
    this.getCookies();
    this.getInTouch = this.fb.group({
      name: ['', Validators.required],
      email_id: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      message: ['', Validators.required],
      recaptcha: [null, Validators.required]
    });
  }


  onSubmit() {
    if (this.getInTouch.get('recaptcha').untouched) {
      $('.error').fadeIn();
      setTimeout(() => {
        $('.error').fadeOut();
      }, 3000);
    } else {

      this.formData = {
        form_type: 'get-in-touch',
        name: this.getInTouch.get('name').value,
        email_id: this.getInTouch.get('email_id').value,
        phone: this.getInTouch.get('phone').value,
        subject: '',
        message: this.getInTouch.get('message').value
      };
      if (this.getInTouch.valid) {
        $('.get-touch').fadeIn();
        this.cmsService.submitFormsService(this.formData)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(res => {
            $('.get-touch').fadeOut();
            if (res.code === 200) {
              this.formMessage = res.message;
              $('.form-overlay .overlay').fadeIn();
              setTimeout(() => {
                grecaptcha.reset();
                this.googleCaptcha = false;
                this.getInTouch.reset();
                this.formMessage = '';
                $('.form-overlay .overlay').fadeOut();
                $('.ti-close').trigger('click');
              }, 3000);
            }
          });
      }
    }
  }


  /** comment
   * to get categories
   */
  CategoryList() {
    this.cmsService.getFaqCategoryList()
      .pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        this.categories = res.data;
      });
  }

  /** comment
   * to set cookies
   */
  setCookies() {
    this.cookiesService.set('visitor', 'eleven plus', 365);
    this.getCookies();
  }

  /** comment
   * to get cookies
   */
  getCookies() {
    this.cookieValue = this.cookiesService.get('visitor');
  }

  /** comment
   * get current year
   */
  getCurrentYear() {
    const year = new Date();
    return year.getFullYear();
  }

  ngAfterContentInit(): void {

    $(document).ready(() => {
      setInterval(function () {
        $('.cookies-bar').addClass('open');
      }, 30);
      $(window).scroll(function () {
        if ($(this).scrollTop() > 800) {
          $('.gototop')
            .addClass('isvisible')
            .fadeIn(300);

        } else {
          $('.gototop')
            .removeClass('isvisible')
            .fadeOut(300);
        }
      });



      $('.gototop').on('click', () => {
        $('body,html').animate(
          {
            scrollTop: 0
          },
          700
        );
      });


      $(window).scroll(function () {
        if (
          $(window).scrollTop() + $(window).height() >
          $(document).height()
        ) {

          $('.cookies-bar').hide();
        } else {
          $('.cookies-bar').show();
        }
      });

      const width = $(window).width();
      if (width < 900) {
        $('header .open-nav').on('click', () => {
          $('nav').addClass('open');
        });

        $('nav .close-nav').on('click', () => {
          $('nav').removeClass('open');
        });

        $('.close_nav').on('click', () => {
          $('nav').removeClass('open');
        });


        $('.process-wrap .row')
          .addClass('owl-carousel')
          .addClass('owl-theme')
          .addClass('process-wrap-slider');
        const galleryLeft = $('.gallery .col-md-7').html();
        $('.gallery .col-md-7').html($('.gallery .col-md-5').html());
        $('.gallery .col-md-5').html(galleryLeft);
      }

      if ($(window).width() > 900) {
        $('.mega-menu-wrap').hover(
          () => {
            $('.mega-menu').removeClass('remove');
          },
          () => {
            $('.mega-menu').addClass('remove');
          }
        );



        $('.sub-menu-wrap').hover(
          () => {
            $('.submenu').removeClass('remove');
          },
          () => {
            $('.submenu').addClass('remove');
          }
        );



        $('.mega-menu a').on('click', () => {
          $('.mega-menu').addClass('remove');
          $('body,html').animate({
            scrollTop: 0
          }, 10);

        });


        $('.mega-menu li').on('click', () => {
          $('.mega-menu').addClass('remove');
          $('body,html').animate({
            scrollTop: 0
          }, 10);
        });


        $('.submenu-sml *').on('click', () => {
          $('.submenu').addClass('remove');
          $('body,html').animate({
            scrollTop: 0
          }, 10);
        });



        const login = $('.my-acc-drop').attr('data-login');
        if (login === 'has-login') {
          $('header').addClass('has-login');
        }
      }

    });
  }


  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
