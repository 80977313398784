import { Component, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-webinar',
  templateUrl: './webinar.component.html',
  styleUrls: ['./webinar.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('open', style({
        opacity: 1,
        transform: 'scale(1)'
      })),
      state('closed', style({
        opacity: 0,
        transform: 'scale(0.8)'
      })),
      transition('closed => open', [
        animate('0.3s ease-in-out')
      ]),
      transition('open => closed', [
        animate('0.2s ease-in-out')
      ])
    ])
  ]
})
export class WebinarComponent {

  constructor() { }


  showCalendar: boolean = false;
  modalState: string = 'closed';
  activeTab: string = 'upcoming';

  events = [
    { date: 'March 15', title: "GCSE Math's Mastery: ", time: "07:00PM", type: "upcoming",link: "https://teams.microsoft.com/l/message/19:d0112af2-3380-4f2f-a9a6-ab362e38fd64_e09fe10a-7f84-4321-bf16-21d9803a7e8c@unq.gbl.spaces/1741102051364?context=%7B%22contextType%22%3A%22chat%22%7D" },
    { date: 'March 28', title: "GCSE English Language Secrets", time: "07:00PM", type: "upcoming",link: "https://teams.microsoft.com/l/message/19:d0112af2-3380-4f2f-a9a6-ab362e38fd64_e09fe10a-7f84-4321-bf16-21d9803a7e8c@unq.gbl.spaces/1741102051364?context=%7B%22contextType%22%3A%22chat%22%7D" },
    { date: 'April 11', title: "GCSE ENGLISH WEBINAR", time: "07:00PM", type: "upcoming",link: "https://teams.microsoft.com/l/message/19:d0112af2-3380-4f2f-a9a6-ab362e38fd64_e09fe10a-7f84-4321-bf16-21d9803a7e8c@unq.gbl.spaces/1741102051364?context=%7B%22contextType%22%3A%22chat%22%7D" },
    { date: 'April 25', title: "ACE GCSE SCIENCE", time: "07:00PM", type: "upcoming",link: "https://teams.microsoft.com/l/message/19:d0112af2-3380-4f2f-a9a6-ab362e38fd64_e09fe10a-7f84-4321-bf16-21d9803a7e8c@unq.gbl.spaces/1741102051364?context=%7B%22contextType%22%3A%22chat%22%7D" },
  ];

  get filteredEvents() {
    return this.events.filter(event => event.type === this.activeTab);
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

   // Open with animation
   openCalendar() {
    this.showCalendar = true;
    setTimeout(() => {
      this.modalState = 'open';
    }, 10);
  }

  // Close with animation
  closeCalendar() {
    this.modalState = 'closed';
    setTimeout(() => {
      this.showCalendar = false;
    }, 200); // Match the closing animation duration
  }

  

}
